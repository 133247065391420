import { Button } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { TeiaQuantityTakeoffColumn } from '../types';
import { TeiaQuantityTakeoffTableCellAggregateField } from './TeiaQuantityTakeoffAggregateField';
import { TeiaQuantityTakeoffColumnField } from './TeiaQuantityTakeoffColumnField';

interface TeiaQuantityTakeoffTopToolbarProps {
  columns: Array<TeiaQuantityTakeoffColumn>;
  columnsOptions: Array<TeiaQuantityTakeoffColumn>;
  groupBy: TeiaQuantityTakeoffColumn | null;
  groupByOptions: Array<TeiaQuantityTakeoffColumn>;
  onColumnsChange: (columns: Array<TeiaQuantityTakeoffColumn>) => void;
  onGroupByChange: (groupBy: TeiaQuantityTakeoffColumn | null) => void;
  canRefresh: boolean;
  onRefresh: () => void;
}

export const TeiaQuantityTakeoffTopToolbar = (props: TeiaQuantityTakeoffTopToolbarProps) => {
  const {
    columns,
    columnsOptions,
    groupBy,
    groupByOptions,
    onColumnsChange,
    onGroupByChange,
    canRefresh,
    onRefresh
  } = props;
  const { t } = usePluginTranslation();

  return (
    <div className={'st-flex st-gap-2 st-items-center st-w-full'}>
      <TeiaQuantityTakeoffColumnField
        columns={columns}
        options={columnsOptions}
        onChange={onColumnsChange}
      />
      <TeiaQuantityTakeoffTableCellAggregateField
        groupBy={groupBy}
        options={groupByOptions}
        onChange={onGroupByChange}
      />
      <Button onClick={onRefresh} disabled={!canRefresh}>
        {t('quantity_takeoff_plugin.refresh_data_button_label')}
      </Button>
    </div>
  );
};
