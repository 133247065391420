import {
  ANOMALY_EXTENSIONS,
  AnomalyApi,
  AnomalyFileType,
  GetAnomaliesQueryParams,
  RequestPostAnomaly,
  TwinType
} from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePluginTranslation } from '@translation';
import toast from 'react-hot-toast';
import { buildAnomalyErrorMessage, formatAnomalyFilename } from '../widgets/utils';

const queryKeys = ['Anomaly'];

export const useAnomalyApi = (projectId: number) => {
  const { t } = usePluginTranslation();
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();

  const getAnomaly = (anomalyId: number) =>
    useQuery({
      queryKey: [...queryKeys, projectId, anomalyId],
      queryFn: () => {
        return AnomalyApi(client).getAnomaly(projectId, anomalyId);
      }
    });

  const getAnomalies = (anomaliesQueryParams: GetAnomaliesQueryParams) =>
    useQuery({
      queryKey: [...queryKeys, projectId, { anomaliesQueryParams }],
      queryFn: () => {
        return AnomalyApi(client).getAnomalies(projectId, anomaliesQueryParams);
      },
      placeholderData: keepPreviousData
    });

  const { mutateAsync: postAnomaly } = useMutation({
    mutationFn: async ({ anomaly }: { anomaly: RequestPostAnomaly }) => {
      await AnomalyApi(client).postAnomaly(projectId, anomaly);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
    }
  });

  const patchAnomaly = (anomalyId: number) =>
    useMutation({
      mutationFn: async ({ anomaly }: { anomaly: RequestPostAnomaly }) => {
        await AnomalyApi(client).patchAnomaly(projectId, anomalyId, anomaly);
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
      }
    });

  const getAnomalyComments = (anomalyId: number) =>
    useQuery({
      queryKey: [...queryKeys, projectId, anomalyId, 'comments'],
      queryFn: () => {
        return AnomalyApi(client).getAnomalyComments(projectId, anomalyId);
      }
    });

  const getAnomalyHistory = (anomalyId: number) => 
    useQuery({
      queryKey: [...queryKeys, projectId, anomalyId, 'history'],
      queryFn: () => {
        return AnomalyApi(client).getAnomalyHistory(projectId, anomalyId);
      }
    });

  const { mutateAsync: postAnomalyComment } = useMutation({
    mutationFn: async ({ comment, anomalyId }: { comment: string; anomalyId: number }) =>
      await AnomalyApi(client).postAnomalyComment(projectId, anomalyId, comment),
    onSuccess: async (_response, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [...queryKeys, projectId, variables.anomalyId, 'comments']
        })
      ]);
    }
  });

  const { mutateAsync: deleteAnomalyComment } = useMutation({
    mutationFn: ({ anomalyId, commentId }: { anomalyId: number; commentId: number }) =>
      AnomalyApi(client).deleteAnomalyComment(projectId, anomalyId, commentId),
    onSuccess: (_response, variables) => {
      queryClient.invalidateQueries({
        queryKey: [...queryKeys, projectId, variables.anomalyId, 'comments']
      });
    }
  });

  const exportAnomalies = (anomalyIds: Array<number>, urlExportType: AnomalyFileType) => {
    return useQuery({
      queryKey: [...queryKeys, projectId, urlExportType, ...anomalyIds],
      queryFn: async () => {
        const searchExportResponse = await AnomalyApi(client).exportAnomalies(
          projectId,
          anomalyIds,
          urlExportType
        );

        const filename = formatAnomalyFilename(anomalyIds, ANOMALY_EXTENSIONS[urlExportType]);

        const blob = await searchExportResponse.blob();
        return {
          fileName: filename,
          blob: blob
        };
      },
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      retry: 0,
      enabled: false
    });
  };

  const { mutateAsync: importBcfAnomaly } = useMutation({
    mutationFn: async ({ file, twinProjectType }: { file: File; twinProjectType: TwinType }) =>
      await AnomalyApi(client).importBcfAnomaly(projectId, file, twinProjectType),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
      toast.success(t('anomaly_plugin.info_widget.upload.success_text'));
    },
    onError: () => {
      toast.error(t('anomaly_plugin.info_widget.upload.error_text'));
    }
  });

  const { mutateAsync: importCsvAnomaly } = useMutation({
    mutationFn: async ({
      file,
      twinProjectType,
      anomalyFileType
    }: {
      file: File;
      twinProjectType: TwinType;
      anomalyFileType: AnomalyFileType;
    }) =>
      await AnomalyApi(client).importCsvAnomaly(projectId, file, twinProjectType, anomalyFileType),
    onSuccess: async (response) => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
      buildAnomalyErrorMessage(response);
    }
  });

  const importAnomaly = (file: File, twinProjectType: TwinType, fileExtension: AnomalyFileType) => {
    if (fileExtension === AnomalyFileType.BCF) {
      return importBcfAnomaly({ file, twinProjectType });
    }
    return importCsvAnomaly({ file, twinProjectType, anomalyFileType: fileExtension });
  };

  return {
    getAnomaly,
    postAnomaly,
    patchAnomaly,
    getAnomalyComments,
    getAnomalyHistory,
    postAnomalyComment,
    getAnomalies,
    deleteAnomalyComment,
    importAnomaly,
    exportAnomalies
  };
};
