import {
  NewSelectionFilter,
  SelectionFilter,
  SelectionFilterApi,
  SelectionFilterType,
  SelectionSortOrder
} from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';

export const useSelectionFiltersApi = (projectId: number) => {
  const queryKeys = ['SelectionFilter'];
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();

  const useGetSelectionFilter = (id: number, options: { enabled: boolean }) =>
    useQuery({
      queryKey: [...queryKeys, projectId, id],
      queryFn: () => {
        return SelectionFilterApi(client).getSelectionFilter(projectId, id);
      },
      ...options
    });

  const useGetSelectionFilters = (
    pageNumber: number,
    pageSize: number,
    searchParams?: {
      name?: string;
      type?: SelectionFilterType;
      isPublic?: boolean;
      sortField?: string;
      sortOrder?: SelectionSortOrder;
    }
  ) =>
    useQuery({
      queryKey: [...queryKeys, projectId, { pageNumber, pageSize, searchParams }],
      queryFn: () => {
        return SelectionFilterApi(client).getSelectionFilters(
          projectId,
          pageNumber,
          pageSize,
          searchParams
        );
      }
    });

  const useGetAllSelectionFilters = () => {
    let pageSize = 1000;
    const { data } = useInfiniteQuery({
      queryKey: [...queryKeys, projectId],
      queryFn: ({ pageParam = 1 }) => {
        return SelectionFilterApi(client).getSelectionFilters(projectId, pageParam, pageSize);
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        pageSize = lastPage.pageSize;
        const nextPage = lastPage.pageNumber + 1;
        if (nextPage > lastPage.totalPages) {
          return undefined;
        }
        return nextPage;
      },
      placeholderData: keepPreviousData
    });

    return data?.pages.flatMap((page) => page.data).sort((a, b) => a.name.localeCompare(b.name));
  };

  const useDeleteFilters = useMutation({
    mutationFn: async (t: { selectionFilterId: number }) =>
      await SelectionFilterApi(client).deleteSelectionFilter(projectId, t.selectionFilterId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
    }
  });

  const usePostSelectionFilter = useMutation({
    mutationFn: async (t: { selectionFilter: NewSelectionFilter }) =>
      await SelectionFilterApi(client).postSelectionFilter(projectId, t.selectionFilter),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
    }
  });

  const usePatchSelectionFilter = useMutation({
    mutationFn: async (t: { selectionFilter: SelectionFilter }) =>
      await SelectionFilterApi(client).patchSelectionFilter(projectId, t.selectionFilter),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
    }
  });

  return {
    useGetSelectionFilter,
    useGetSelectionFilters,
    useGetAllSelectionFilters,
    useDeleteFilters,
    usePostSelectionFilter,
    usePatchSelectionFilter
  };
};
