import QTOAggregateIcon from '@resources/icons/QuantityTakeOffAggregateIcon.svg?react';
import { Paper, Popover, Stack } from '@stereograph/teia-system-design';
import { IconButton } from '@stereograph/teia-system-design/deprecated';
import { usePluginTranslation } from '@translation';
import classNames from 'classnames';
import { MouseEventHandler, useId, useState } from 'react';
import { ColumnAggregateVisibility } from './TeiaQuantityTakeoffTable';
import { TeiaQuantityTakeoffTableHeadAggregateItem } from './TeiaQuantityTakeoffTableHeadAggregateItem';
import { useAggregateVisibility } from './TeiaQuantityTakeoffVisibilityContext';

interface TeiaQuantityTakeoffTableHeadAggregateActionProps {
  column: string;
  className?: string;
  /**
   * Close the popover when the visibility changes
   * @default false
   */
  closeOnChange?: boolean;
}

export const TeiaQuantityTakeoffTableAggregateVisibilityButton = (
  props: TeiaQuantityTakeoffTableHeadAggregateActionProps
) => {
  const { className, column, closeOnChange = false } = props;
  const { t } = usePluginTranslation();
  const { columnsAggregateVisibility, setColumnsAggregateVisibility } = useAggregateVisibility();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const visibility = columnsAggregateVisibility[column];
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (aggregate: keyof ColumnAggregateVisibility) => {
    const columnVisibility = { ...visibility, [aggregate]: !visibility[aggregate] };
    setColumnsAggregateVisibility({ ...columnsAggregateVisibility, [column]: columnVisibility });
    if (closeOnChange) {
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const componentId = useId();
  const id = open ? componentId : undefined;
  if (!visibility) {
    return null;
  }
  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={classNames('st-shrink-0', className)}
    >
      <div className={'st-flex st-items-center'}>
        <IconButton
          variant={'info'}
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          icon={{
            svgIcon: QTOAggregateIcon
          }}
          iconOnly={true}
          aria-label={t('quantity_takeoff_plugin.manage_aggregates_label')}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleClose}
        disableScrollLock
      >
        <Paper sx={{ px: 2, py: 1, pl: 4 }}>
          <Stack spacing={1}>
            <TeiaQuantityTakeoffTableHeadAggregateItem
              label={t('quantity_takeoff_plugin.aggregate_action_item.count_label')}
              checked={visibility.count}
              onChange={() => handleOnChange('count')}
            />
            <TeiaQuantityTakeoffTableHeadAggregateItem
              label={t('quantity_takeoff_plugin.aggregate_action_item.min_label')}
              checked={visibility.min}
              onChange={() => handleOnChange('min')}
            />
            <TeiaQuantityTakeoffTableHeadAggregateItem
              label={t('quantity_takeoff_plugin.aggregate_action_item.max_label')}
              checked={visibility.max}
              onChange={() => handleOnChange('max')}
            />
            <TeiaQuantityTakeoffTableHeadAggregateItem
              label={t('quantity_takeoff_plugin.aggregate_action_item.sum_label')}
              checked={visibility.sum}
              onChange={() => handleOnChange('sum')}
            />
            <TeiaQuantityTakeoffTableHeadAggregateItem
              label={t('quantity_takeoff_plugin.aggregate_action_item.average_label')}
              checked={visibility.average}
              onChange={() => handleOnChange('average')}
            />
          </Stack>
        </Paper>
      </Popover>
    </div>
  );
};
