import {
  CommandDevToolsControlsMap,
  CommandDevToolsPane,
  InfoDevToolsPane,
  ObjectDevToolsPane,
  PluginsSettingsDevToolsPane,
  QuickActionDevToolsPane,
  SettingsDevToolsPane,
  TeiaLoadingDevToolsPane,
  TweakPaneContainer
} from '@stereograph/teiaviewer';
import { TeiaLoginDevToolsPane } from './TeiaLoginDevToolsPane';

export const CustomDevToolsWidgetContent = () => {
  return (
    <TweakPaneContainer>
      <TeiaLoginDevToolsPane />
      <SettingsDevToolsPane />
      <PluginsSettingsDevToolsPane />
      <CommandDevToolsPane controlMap={CommandDevToolsControlsMap} />
      <ObjectDevToolsPane />
      <TeiaLoadingDevToolsPane />
      <QuickActionDevToolsPane />
      <InfoDevToolsPane />
    </TweakPaneContainer>
  );
};
