import {
  Button,
  ModalView,
  ModalViewContent,
  ModalViewFooter
} from '@stereograph/teia-system-design/deprecated';
import { useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';

interface DeleteSelectionFilterModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  selectionFilterId: number;
}

interface FormValues {
  selectionFilterId: number;
}

export const TeiaDeleteSelectionFilterModal = (props: DeleteSelectionFilterModalProps) => {
  const { isModalOpen, onClose, selectionFilterId } = props;
  const { t } = usePluginTranslation();
  const { projectId } = useTwinSource();
  const {
    useDeleteFilters: { mutateAsync }
  } = useSelectionFiltersApi(projectId);

  const methods = useForm<FormValues>({
    defaultValues: { selectionFilterId: selectionFilterId },
    mode: 'onChange'
  });

  const handleDeleteSelection: SubmitHandler<FormValues> = (data) => {
    mutateAsync({ selectionFilterId: data.selectionFilterId });
  };

  const onCloseModal = () => {
    methods.reset();
    onClose();
  };

  return (
    <form onSubmit={methods.handleSubmit(handleDeleteSelection)}>
      <ModalView
        id="new-connector-modal"
        isFooter={true}
        variant="white"
        size="sm"
        show={isModalOpen}
        onClose={onCloseModal}
        title={t('selection_plugin.record_widget.selection_delete.modal.title')}
      >
        <ModalViewContent>
          <div className="st-flex st-items-center st-justify-center st-flex-col st-py-5 st-mb-2 st-bg-red-600">
            <p className="st-text-white st-text-xl">
              {t('selection_plugin.record_widget.selection_delete.modal.banner.text')}
            </p>
          </div>
          <ModalViewFooter>
            <div className="sm:st-flex st-ml-auto st-mr-auto dark:st-bg-slate-500">
              <Button variant="info" type="button" onClick={onCloseModal} className="st-mr-6">
                {t('selection_plugin.record_widget.selection_delete.modal.cancel_button.label')}
              </Button>
              <Button type="submit" variant="danger">
                {t('selection_plugin.record_widget.selection_delete.modal.validate_button.label')}
              </Button>
            </div>
          </ModalViewFooter>
        </ModalViewContent>
      </ModalView>
    </form>
  );
};
