import {
  StandardWidget,
  StandardWidgetContextProviderProps,
  StandardWidgetOptions,
  TeiaViewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { AnomalyContextProvider } from '../context/AnomalyContext';
import { TeiaAnomalyResumeWidgetContent } from './TeiaAnomalyResumeWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    TeiaAnomalyResume: WidgetFactoryFunction<TeiaAnomalyResumeWidget, { projectId: number }>;
  }
}

export class TeiaAnomalyResumeWidget extends StandardWidget {
  readonly type = 'TeiaAnomalyResume';
  anomalyId?: number;
  projectId: number;

  constructor(viewer: TeiaViewer, projectId: number, options: StandardWidgetOptions = {}) {
    super(viewer, {
      ...options,
      color: 'info'
    });

    this.projectId = projectId;
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return t('anomaly_plugin.resume_widget.title');
  };

  override Content = () => {
    return <TeiaAnomalyResumeWidgetContent />;
  };

  protected override ContextProvider = (props: StandardWidgetContextProviderProps) => (
    <AnomalyContextProvider projectId={this.projectId} {...props} />
  );

  override Footer = () => {
    return null;
  };
}
