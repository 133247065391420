import { TeiaSearchFilter, TwinApi } from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { teiaQuantityTakeoffObjectsDTO } from '../utils/teiaQuantityTakeoffObjectsDTO';
import { teiaQuantityTakeoffQueryKeys } from '../utils/teiaQuantityTakeoffQueryKeys';

export const useQuantityTakeoffObject = (
  rule: TeiaSearchFilter,
  columns: Array<string>,
  groupBy?: string,
  pageIndex = 1,
  pageSize = 100
) => {
  const { viewer } = useTeiaViewerContext();
  const twin = useTwinSource();
  const client = viewer.twinApiClient;

  const { data, isLoading, error } = useQuery({
    queryKey: teiaQuantityTakeoffQueryKeys.objects(
      rule,
      columns,
      groupBy ?? '',
      pageIndex,
      pageSize
    ),
    queryFn: async () => {
      const { data, ...restResponse } = await TwinApi(client).searchExport(
        twin,
        {
          rule: rule,
          columns: columns,
          group: groupBy ?? ''
        },
        pageIndex,
        pageSize
      );
      const flatTakeoffObjects = teiaQuantityTakeoffObjectsDTO(data.datas, groupBy);
      return {
        data: flatTakeoffObjects,
        ...restResponse
      };
    },
    placeholderData: keepPreviousData
  });

  return {
    data,
    isLoading,
    error
  };
};
