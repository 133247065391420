import {
  StandardWidget,
  StandardWidgetOptions,
  TeiaViewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { AnomalyContextProvider } from '../context/AnomalyContext';
import { TeiaUpdateAnomalyWidgetContent } from './TeiaUpdateAnomalyWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    TeiaUpdateAnomaly: WidgetFactoryFunction<
      TeiaUpdateAnomalyWidget,
      { projectId: number; anomalyId: number }
    >;
  }
}

export class TeiaUpdateAnomalyWidget extends StandardWidget {
  readonly type = 'TeiaUpdateAnomaly';
  projectId: number;
  anomalyId: number;

  constructor(
    viewer: TeiaViewer,
    projectId: number,
    anomalyId: number,
    options: StandardWidgetOptions = {}
  ) {
    super(viewer, {
      ...options,
      color: 'info'
    });

    this.projectId = projectId;
    this.anomalyId = anomalyId;
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return t('anomaly_plugin.widget.update_title');
  };

  override Content = () => {
    return (
      <AnomalyContextProvider projectId={this.projectId}>
        <TeiaUpdateAnomalyWidgetContent anomalyId={this.anomalyId} />
      </AnomalyContextProvider>
    );
  };

  override Footer = () => {
    return null;
  };
}
