import React from 'react';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { useAuth } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

export enum Environment {
  Develop = 'Develop',
  Staging = 'Staging',
  Preprod = 'Preprod',
  Localhost = 'Localhost'
}

function getAuthConfig(targetEnv: Environment) {
  function getEnvPrefix(env: Environment) {
    switch (env) {
      case Environment.Develop:
      case Environment.Localhost:
        return 'develop';
      case Environment.Staging:
        return 'staging';
      case Environment.Preprod:
        return 'preprod';
    }
  }

  return {
    authority: `https://identity-${getEnvPrefix(targetEnv)}.teia-solution.com`,
    redirect_uri: location.href,
    client_id: 'teia.viewer',
    scope: 'digitaltwin.ro openid offline_access profile',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({
      store: window.localStorage,
      prefix: 'teiaviewer.demo.' + getEnvPrefix(targetEnv) + '.'
    }),
    post_logout_redirect_uri: location.origin + location.pathname
  } satisfies AuthProviderProps;
}

export interface TeiaAuthContext {
  targetEnv: Environment;
  setTargetEnv: (env: Environment) => void;
}

const Context = React.createContext<TeiaAuthContext | null>(null);
interface TeiaAuthProviderProps {
  defaultEnvironment: Environment;
  children?: React.ReactNode;
}

export const TeiaAuthProvider = (props: TeiaAuthProviderProps) => {
  const { defaultEnvironment = Environment.Develop, children } = props;

  // We setup the default environment from the local storage if any redirection is already in progress
  const env = localStorage.getItem('viewer-target-env') as Environment | null;

  const [targetEnv, setTargetEnv] = React.useState(env ?? defaultEnvironment);

  const setTEnv = (env: Environment) => {
    localStorage.setItem('viewer-target-env', env);
    setTargetEnv(env);
  };

  const config = getAuthConfig(targetEnv);

  const onSigninCallback = () => {
    // Get the base url from the redirect url
    const url = new URL(config.redirect_uri);
    const originURL = new URL(url.origin + url.pathname);

    // Put back the seach params if any
    const params = localStorage.getItem('viewer-redirect-search-params');
    const searchParams = new URLSearchParams(params ?? '');
    for (const [key, value] of searchParams.entries()) {
      originURL.searchParams.set(key, value);
    }
    localStorage.removeItem('viewer-redirect-search-params');

    // Update the window location
    window.history.replaceState({}, document.title, originURL);
  };
  return (
    <Context.Provider value={{ targetEnv, setTargetEnv: setTEnv }}>
      <AuthProvider {...config} onSigninCallback={onSigninCallback} key={targetEnv}>
        {children}
      </AuthProvider>
    </Context.Provider>
  );
};

export const useTeiaAuth = () => {
  const auth = useAuth();
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useTeiaAuth must be called inside TeiaAuthProvider');
  }

  const signinRedirect = () => {
    // We extract search params from the redirect url so Identity accepts the redirect url
    // We store store in the local storage to take them back when authenticated
    const url = new URL(auth.settings.redirect_uri);
    const redirect_uri = url.origin + url.pathname;
    localStorage.setItem('viewer-redirect-search-params', url.searchParams.toString());
    auth.signinRedirect({ redirect_uri });
  };

  return { ...auth, ...context, signinRedirect };
};
