import {
  StandardWidget,
  StandardWidgetOptions,
  TeiaViewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { TeiaOpenDataWidgetContent } from './TeiaOpenDataWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    TeiaOpenData: WidgetFactoryFunction<TeiaOpenDataWidget>;
  }
}

export class TeiaOpenDataWidget extends StandardWidget {
  readonly type = 'TeiaOpenData';

  constructor(viewer: TeiaViewer, options: StandardWidgetOptions = {}) {
    const { ...otherOptions } = options;

    super(viewer, {
      color: 'info',
      ...otherOptions
    });
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return (
      <div className="st-flex st-gap-1 st-items-center st-uppercase">
        {t('open_data_plugin.title')}
      </div>
    );
  };

  override Content = () => {
    return <TeiaOpenDataWidgetContent />;
  };

  override Footer = () => {
    return null;
  };
}
