import {
  StandardWidget,
  StandardWidgetOptions,
  TeiaViewer,
  TeiaViewerSourceType,
  useTeiaSource,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { TeiaPatchDataWidgetContent } from './TeiaPatchDataWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    TeiaPatchData: WidgetFactoryFunction<TeiaPatchDataWidget>;
  }
}

export class TeiaPatchDataWidget extends StandardWidget {
  readonly type = 'TeiaPatchData';

  constructor(viewer: TeiaViewer, options: StandardWidgetOptions = {}) {
    super(viewer, {
      ...options,
      color: 'info'
    });
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return t('patch_data_plugin.title');
  };

  override Content = () => {
    const twin = useTeiaSource(TeiaViewerSourceType.Twin);
    if (!twin) {
      return null;
    }

    return <TeiaPatchDataWidgetContent />;
  };

  override Footer = () => {
    return null;
  };
}
