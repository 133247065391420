import { TeiaSearchFilter } from '@stereograph/teia-system-design/twin-api';
import { useEffect, useRef, useState } from 'react';
import { useTwinSearchExport } from './useTwinSearchExport';

export interface UseDownloadSearchExport {
  searchRules: TeiaSearchFilter;
  tableColumns: Array<string>;
  groupBy: string | null;
}

export const useDownloadSearchExport = (queryParams: UseDownloadSearchExport) => {
  const { searchRules, tableColumns, groupBy } = queryParams;
  const lastFileUpdate = useRef(Date.now());
  const [fileName, setFileName] = useState<string>('');
  const {
    data: file,
    refetch: downloadExport,
    isLoading: isDownloading,
    dataUpdatedAt
  } = useTwinSearchExport({
    rule: searchRules,
    columns: tableColumns,
    group: groupBy ?? ''
  });

  const downloadFile = (name?: string) => {
    if (!file) {
      return;
    }
    const url = window.URL.createObjectURL(file.blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name && name.length > 0 ? name : file.fileName;
    a.click();
    a.remove();
  };

  /**
   * Download file when data (the file content) is updated
   */
  useEffect(() => {
    if (file !== undefined && dataUpdatedAt > lastFileUpdate.current) {
      lastFileUpdate.current = dataUpdatedAt;
      downloadFile(fileName);
    }
  }, [file]);

  const downloadSearchExport = (name?: string) => {
    setFileName(name ?? '');
    if (isDownloading) {
      return;
    }
    if (!file) {
      return downloadExport();
    }
    return downloadFile(name);
  };

  return { downloadSearchExport, isDownloading };
};
