import {
  TwinObjectQuantityTakeoff,
  TwinObjectQuantityTakeoffPropertyAggregate
} from '@stereograph/teia-system-design/twin-api';
import { TeiaQuantityTakeoffRow, TeiaQuantityTakeoffRows } from '../types';

const isAggregate = (
  object: TwinObjectQuantityTakeoff
): object is TwinObjectQuantityTakeoffPropertyAggregate => {
  return (
    'name' in object &&
    'objectQuantityTakeOffDtos' in object &&
    object.objectQuantityTakeOffDtos.length > 0
  );
};

export const teiaQuantityTakeoffObjectsDTO = (
  objects: Array<TwinObjectQuantityTakeoff>,
  groupBy?: string
) => {
  const objectsData: TeiaQuantityTakeoffRows = [];
  for (const twinObject of objects) {
    const flatObject: TeiaQuantityTakeoffRow = {};
    const isAggregateObject = groupBy && isAggregate(twinObject);
    if (isAggregateObject) {
      /**
       * If the object is an aggregate object, we flatten the object
       * groupBy property become the key of the object
       * the name of the object become the value of the key because it is the value of the groupBy
       * like this: "IfcWall"
       */
      flatObject[groupBy] = twinObject.name;
      const aggregate = twinObject.objectQuantityTakeOffDtos.find(
        (property) => property.name === groupBy
      );
      if (aggregate) {
        flatObject[groupBy] = `${twinObject.name} (${aggregate.count})`;
      }
    }
    for (const property of twinObject.objectQuantityTakeOffDtos) {
      if ('name' in property && 'value' in property) {
        flatObject[property.name] = property.value;
      } else if (isAggregateObject && property.name !== groupBy) {
        flatObject[property.name] = property;
      }
    }
    objectsData.push(flatObject);
  }
  return objectsData;
};
