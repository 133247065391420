import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Text
} from '@stereograph/teia-system-design';
import { Spinner } from '@stereograph/teia-system-design/deprecated';
import { usePluginTranslation } from '@translation';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { useAnomalyContext } from '../context/AnomalyContext';
import { CreateAnomalyCommentForm } from './CreateAnomalyCommentForm';
import { TeiaAnomalyCommentList } from './TeiaAnomalyCommentList';

interface TeiaAnomalyCommentProps {
  anomalyId: number;
}

export const TeiaAnomalyComment = (props: TeiaAnomalyCommentProps) => {
  const { anomalyId } = props;
  const { projectId } = useAnomalyContext();
  const { getAnomalyComments } = useAnomalyApi(projectId);
  const { data: comments, isLoading } = getAnomalyComments(anomalyId);
  const { t } = usePluginTranslation();

  if (isLoading) {
    return <Spinner />;
  }

  if (!comments) {
    return null;
  }

  return (
    <Box key="anomaly-history-accordion">
      <Accordion color="info">
        <AccordionSummary>
          <Text variant="lead">{t('anomaly_plugin.widget.comment_form.comment_title')}</Text>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: 4 }}>
            <TeiaAnomalyCommentList comments={comments.data} anomalyId={anomalyId} />
            <CreateAnomalyCommentForm anomalyId={anomalyId} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
