import { Tooltip, IconButton, Icon } from '@stereograph/teia-system-design';
import { PencilIcon } from '@heroicons/react/24/outline';
import { OpenWidgetCommand, useTeiaViewerContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useAnomalyContext } from '../../context/AnomalyContext';

interface EditAnomalyButton {
  anomalyId: number;
}

export const EditAnomalyButton = (props: EditAnomalyButton) => {
  const { anomalyId } = props;
  const { projectId } = useAnomalyContext();
  const { viewer } = useTeiaViewerContext();
  const { t } = usePluginTranslation();

  const openCreateAnomalyWidget = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaUpdateAnomaly', {
      projectId,
      anomalyId
    });
    command.execute();
  };

  return (
    <Tooltip
      title={t('anomaly_plugin.resume_widget.table.columns.actions.edit_tooltip')}
      placement={'top'}
    >
      <IconButton
        onClick={openCreateAnomalyWidget}
        aria-label={t('anomaly_plugin.resume_widget.table.columns.actions.edit_tooltip')}
      >
        <Icon component={PencilIcon} />
      </IconButton>
    </Tooltip>
  );
};
