import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack, Textarea } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as z from 'zod';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { useAnomalyContext } from '../context/AnomalyContext';

interface CreateAnomalyCommentFormProps {
  anomalyId: number;
}

export const commentSchema = z.object({
  comment: z.string().trim().min(1)
});

type CommentFormSchema = z.infer<typeof commentSchema>;

export const CreateAnomalyCommentForm = (props: CreateAnomalyCommentFormProps) => {
  const { anomalyId } = props;
  const { projectId } = useAnomalyContext();
  const { postAnomalyComment } = useAnomalyApi(projectId);
  const { t } = usePluginTranslation();

  const methods = useForm<CommentFormSchema>({
    resolver: zodResolver(commentSchema),
    mode: 'onSubmit',
    defaultValues: {
      comment: ''
    }
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful }
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitComment = (formComment: CommentFormSchema) => {
    const comment = formComment.comment;

    toast.promise(postAnomalyComment({ comment, anomalyId }), {
      loading: t('anomaly_plugin.widget.comment_form.submit.loading.text'),
      success: t('anomaly_plugin.widget.comment_form.submit.success.text'),
      error: t('anomaly_plugin.widget.comment_form.submit.error.text')
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitComment)}>
      <Stack
        direction="row"
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100%',
          paddingY: 4
        }}
      >
        <Controller
          control={control}
          name="comment"
          render={({ field, fieldState: { error } }) => {
            return (
              <Textarea
                name={field.name}
                onChange={field.onChange}
                sx={{ minWidth: 400, width: '80%' }}
                id={field.name}
                placeholder={t('anomaly_plugin.widget.comment_form.placeholder')}
                label={t('anomaly_plugin.widget.comment_form.label')}
                error={error !== undefined}
                helperText={error?.message}
                required
              />
            );
          }}
        />
        <Button type="submit">{t('anomaly_plugin.widget.comment_form.submit.create.label')}</Button>
      </Stack>
    </form>
  );
};
