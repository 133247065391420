import { Stack } from '@stereograph/teia-system-design';
import { Spinner } from '@stereograph/teia-system-design/deprecated';
import {
  TeiaViewer,
  TeiaViewerSourceType,
  useTeiaSource,
  useViewerContext,
  ViewportScreenshotCommand
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useEffect, useState } from 'react';
import { SelectionBanner } from '../../../components/SelectionBanner';
import { createGuidRules } from '../utils';
import { TeiaPatchDataWidgetForm } from './TeiaPatchDataWidgetForm';

export const TeiaPatchDataWidgetContent = () => {
  const { viewer } = useViewerContext<TeiaViewer>();
  const { t } = usePluginTranslation();
  const [selection, _setSelection] = useState(viewer.scene.objectSelection);
  const twin = useTeiaSource(TeiaViewerSourceType.Twin);
  const [viewportImage, setViewportImage] = useState('');

  useEffect(() => {
    getViewportImage();
  }, []);

  const getViewportImage = async () => {
    const image = await new ViewportScreenshotCommand(viewer).execute();
    setViewportImage(image);
  };

  if (!twin || selection.length === 0) {
    return null;
  }

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
      spacing={4}
    >
      <SelectionBanner
        label={t('patch_data_plugin.selected_objects.label', {
          count: selection.length
        })}
        selection={selection}
      />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100%',
          padding: 4
        }}
      >
        {selection.length > 0 && viewportImage ? (
          <TeiaPatchDataWidgetForm
            twin={twin}
            image={viewportImage}
            selection={selection}
            filter={createGuidRules(selection.getUuids())}
          />
        ) : (
          <Spinner size="lg" />
        )}
      </Stack>
    </Stack>
  );
};
