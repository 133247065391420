import { Bold, Box, Divider, Stack, Text, TextField } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { useAllSelectionFilters } from '../../hooks/useSelectionFilter';
import { SelectionCheckbox } from './TeiaQuantityTakeoffSelectionCheckbox';

export const TeiaQuantityTakeoffSelectionList = () => {
  const { t } = usePluginTranslation();
  const selectionFilters = useAllSelectionFilters();
  const [selectionSearch, setSelectionSearch] = useState<string>('');
  const filteredSelections = (selectionFilters ?? []).filter((selection) => {
    return selection.name.toLowerCase().includes(selectionSearch.toLowerCase());
  });

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={2}
        sx={{
          pl: 2
        }}
      >
        <Text>
          <Bold>{t('quantity_takeoff_plugin.load_from_selection.search_form.title')}</Bold>
        </Text>
        <TextField
          label={t('quantity_takeoff_plugin.load_from_selection.search_form.input.label')}
          value={selectionSearch}
          onChange={(event) => setSelectionSearch(event.target.value)}
        />
      </Stack>
      <Divider />
      <Box
        sx={{
          height: '250px',
          overflowY: 'auto',
          paddingLeft: 2
        }}
      >
        <Stack>
          {filteredSelections.map((selection) => (
            <SelectionCheckbox key={selection.id} selection={selection} />
          ))}
          {filteredSelections.length === 0 && (
            <Text
              sx={{
                pt: 2
              }}
            >
              {selectionSearch.length === 0 &&
                t('quantity_takeoff_plugin.load_from_selection.no_selection.text')}
              {selectionSearch.length > 0 &&
                t('quantity_takeoff_plugin.load_from_selection.no_selection_for_search.text', {
                  search: selectionSearch
                })}
            </Text>
          )}
        </Stack>
      </Box>
    </>
  );
};
