import {
  StandardWidget,
  StandardWidgetContextProviderProps,
  StandardWidgetOptions,
  TeiaViewer,
  TeiaViewerSourceType,
  useTeiaSource,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { TeiaPartialLoadingWidgetContent } from './TeiaPartialLoadingWidgetContent';
import { TeiaPartialLoadingWidgetFooter } from './TeiaPartialLoadingWidgetFooter';
import { TeiaPartialLoadingWidgetProvider } from './TeiaPartialLoadingWidgetProvider';

declare global {
  interface ViewerWidgetRegistry {
    TeiaPartialLoading: WidgetFactoryFunction<TeiaPartialLoadingWidget>;
  }
}

export class TeiaPartialLoadingWidget extends StandardWidget {
  readonly type = 'TeiaPartialLoading';

  constructor(viewer: TeiaViewer, options: StandardWidgetOptions) {
    const { ...otherOptions } = options;

    super(viewer, {
      color: 'info',
      minWidth: 400,
      minHeight: 400,
      ...otherOptions
    });
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return t('partial_loading_plugin.title');
  };

  override Content = () => {
    const twin = useTeiaSource(TeiaViewerSourceType.Twin);
    if (!twin) return null;
    return <TeiaPartialLoadingWidgetContent />;
  };

  override Footer = () => {
    const twin = useTeiaSource(TeiaViewerSourceType.Twin);
    if (!twin) return null;
    return <TeiaPartialLoadingWidgetFooter />;
  };

  override ContextProvider = (props: StandardWidgetContextProviderProps) => {
    const twin = useTeiaSource(TeiaViewerSourceType.Twin);
    if (!twin) return null;
    return <TeiaPartialLoadingWidgetProvider {...props} twin={twin} />;
  };
}
