import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack, TextField } from '@stereograph/teia-system-design';
import { OpenDataAction, OpenDataRequest } from '@stereograph/teia-system-design/twin-api';
import { ObjectSelection, useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import i18next from 'i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as z from 'zod';
import { useOpenDataApi } from '../api/useOpenDataApi';
import { OpenDataActionFieldList } from '../components/OpenDataActionFieldList';

const propertySchema = z
  .array(
    z.object({
      key: z.string().trim().min(1),
      value: z.string().trim().min(1),
      action: z.nativeEnum(OpenDataAction)
    })
  )
  .nonempty();

const openDataSchema = z.object({
  name: z.string().trim().min(1),
  twinObjectGuids: z.array(z.string().trim().min(1))
});

const openDataFormSchema = z.object({
  openDataSchema,
  propertySchema
});

export type OpenDataSchema = z.infer<typeof openDataFormSchema>;

interface TeiaOpenDataFormProps {
  selection: ObjectSelection;
  onCancel: () => void;
  onSubmitSuccess: () => void;
}

export const TeiaOpenDataForm = (props: TeiaOpenDataFormProps) => {
  const { selection, onCancel, onSubmitSuccess } = props;
  const twin = useTwinSource();
  const { submitOpenData } = useOpenDataApi(twin);
  const { t } = usePluginTranslation();
  const defaultOpenDataName = getDefaultInputName(`${t('open_data_plugin.text')}`);

  const methods = useForm<OpenDataSchema>({
    resolver: zodResolver(openDataFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      openDataSchema: {
        name: defaultOpenDataName,
        twinObjectGuids: Array.from(selection.getUuids())
      },
      propertySchema: [
        {
          action: OpenDataAction.Add,
          key: '',
          value: ''
        }
      ]
    }
  });

  const { handleSubmit, control } = methods;

  const onSubmit = async (data: OpenDataSchema) => {
    const openDataRequest: OpenDataRequest = {
      name: data.openDataSchema.name,
      twinObjectGuids: data.openDataSchema.twinObjectGuids,
      properties: data.propertySchema
    };

    toast.promise(submitOpenData({ openDataRequest }), {
      loading: t('open_data_plugin.form.submit.loading.text'),
      success: () => {
        onSubmitSuccess();
        return t('open_data_plugin.form.submit.success.text');
      },
      error: () => t('open_data_plugin.form.submit.error.text')
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="st-w-full">
        <Stack spacing={4}>
          <Controller
            control={control}
            name="openDataSchema.name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={field.name}
                label={t('open_data_plugin.form.name.label')}
                placeholder={t('patch_data_plugin.form.name.placeholder')}
                error={error !== undefined}
                helperText={error?.message}
                required
                onChange={field.onChange}
                name={field.name}
                value={field.value}
              />
            )}
          />
          <OpenDataActionFieldList />
          <Stack direction="row" spacing={4} justifyContent="end">
            <Button type="button" color="info" onClick={() => onCancel()}>
              {t('open_data_plugin.form.cancel_button.label')}
            </Button>
            <Button type="submit" color="primary">
              {t('open_data_plugin.form.validate_button.label')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

const getDefaultInputName = (label: string): string => {
  const date = new Date();
  let formattedDate = date.toLocaleDateString(i18next.language, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  });
  formattedDate = formattedDate.replace(/\//g, '-');
  return `${formattedDate} ${label} ${date.getTime()}`;
};
