import { Button, Checkbox, Stack, TextField } from '@stereograph/teia-system-design';
import {
  ModalView,
  ModalViewContent,
  ModalViewFooter
} from '@stereograph/teia-system-design/deprecated';
import { SelectionFilter } from '@stereograph/teia-system-design/twin-api';
import { useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';

interface TeiaUpdateSelectionModalProps {
  selection: SelectionFilter;
  isModalOpen: boolean;
  onClose: () => void;
}

export const TeiaUpdateSelectionModal = (props: TeiaUpdateSelectionModalProps) => {
  const { selection, onClose, isModalOpen } = props;
  const { t } = usePluginTranslation();
  const { projectId } = useTwinSource();
  const {
    usePatchSelectionFilter: { mutateAsync, isPending }
  } = useSelectionFiltersApi(projectId);

  const methods = useForm<SelectionFilter>({
    defaultValues: {
      name: selection.name,
      isPublic: selection.isPublic
    },
    mode: 'onChange'
  });

  const onCloseModal = () => {
    methods.reset();
    onClose();
  };

  const handleSubmit: SubmitHandler<SelectionFilter> = (data) => {
    if (isPending) {
      return;
    }

    const selectionFilter: SelectionFilter = {
      ...selection,
      name: data.name,
      isPublic: data.isPublic
    };

    mutateAsync(
      { selectionFilter: selectionFilter },
      {
        onSuccess: onCloseModal
      }
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
      <ModalView
        id="new-selection-modal"
        isFooter={true}
        variant="white"
        size="sm"
        show={isModalOpen}
        onClose={onCloseModal}
        title={t('selection_plugin.record_widget.selection_update.modal.title')}
      >
        <ModalViewContent>
          <Stack
            spacing={1}
            sx={{
              mb: 2
            }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  label={t('selection_plugin.record_widget.selection_save.modal.name.label')}
                  placeholder={t(
                    'selection_plugin.record_widget.selection_save.modal.name.placeholder'
                  )}
                  required
                  {...field}
                />
              )}
              name={'name'}
              control={methods.control}
              rules={{ required: true, min: 1 }}
            />
            {!selection.isPublic && (
              <Controller
                name="isPublic"
                control={methods.control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    label={t(
                      'selection_plugin.record_widget.selection_save.modal.visibility.label'
                    )}
                    defaultChecked={selection.isPublic}
                    onChange={field.onChange}
                  />
                )}
              />
            )}
          </Stack>
          <ModalViewFooter>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <Button color="info" type="button" onClick={onCloseModal}>
                {t('selection_plugin.record_widget.selection_save.modal.cancel_button.label')}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={!methods.formState.isValid}
                isLoading={isPending}
                sx={{
                  minWidth: 100
                }}
              >
                {t('selection_plugin.record_widget.selection_save.modal.validate_button.label')}
              </Button>
            </Stack>
          </ModalViewFooter>
        </ModalViewContent>
      </ModalView>
    </form>
  );
};
