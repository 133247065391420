import { Stack } from '@stereograph/teia-system-design';
import { useTeiaViewerContext, ViewportScreenshotCommand } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useEffect, useState } from 'react';
import { SelectionBanner } from '../../../components/SelectionBanner';
import { TeiaCreateAnomalyForm } from '../components/TeiaCreateAnomalyForm';
import { useAnomalyPerspectiveCamera } from '../hooks/useAnomalyPerspectiveCamera';
import { useFormatAnomalyGuids } from '../hooks/useFormatAnomalyGuids';

export const TeiaCreateAnomalyWidgetContent = () => {
  const { viewer } = useTeiaViewerContext();
  const { t } = usePluginTranslation();
  const selection = viewer.scene.objectSelection;
  const [snapshot, setSnapshot] = useState('');
  const camera = useAnomalyPerspectiveCamera();
  const anomalyGuids = useFormatAnomalyGuids(viewer);

  useEffect(() => {
    getSnapshot();
  }, []);

  const getSnapshot = async () => {
    const image = await new ViewportScreenshotCommand(viewer).execute();
    setSnapshot(image);
  };

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        padding: 4,
        overflowY: 'auto',
        height: '100%'
      }}
    >
      {selection.length > 0 && (
        <SelectionBanner
          selection={selection}
          label={t('patch_data_plugin.selected_objects.label', {
            count: selection.length
          })}
        />
      )}
      {snapshot && camera && (
        <TeiaCreateAnomalyForm snapshot={snapshot} anomalyGuids={anomalyGuids} camera={camera} />
      )}
    </Stack>
  );
};
