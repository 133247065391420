import { InboxStackIcon } from '@heroicons/react/24/outline';
import SaveIcon from '@resources/icons/SaveIcon.svg?react';
import {
  OpenWidgetCommand,
  TeiaViewer,
  ToolbarItem,
  useTeiaSource,
  useTwinSource,
  useViewerContext,
  useViewerSelection
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { TeiaSaveSelectionModal } from './components/TeiaSaveSelectionModal';

export const SaveTeiaObjectsSelection = () => {
  const { t } = usePluginTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const twin = useTwinSource();
  const { selection } = useViewerSelection();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <ToolbarItem
        disabled={selection.length === 0}
        hidden={!twin}
        icon={{ svgIcon: SaveIcon }}
        label={t('toolbar.save.tooltip')}
        onClick={onClick}
      />
      {twin &&
        createPortal(
          <TeiaSaveSelectionModal isModalOpen={isModalOpen} onClose={onClose} />,
          document.getElementById('app') as Element
        )}
    </>
  );
};

export const ShowTeiaSelectionRecordWidget = () => {
  const { t } = usePluginTranslation();
  const { viewer } = useViewerContext<TeiaViewer>();
  const source = useTeiaSource();

  const onClick = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaSelectionRecord');
    command.execute();
  };

  const toolbarItemProps = {
    hidden: source === null,
    icon: { heroIcon: InboxStackIcon },
    onClick: onClick,
    label: t('toolbar.list_objects.tooltip')
  };

  return <ToolbarItem {...toolbarItemProps} />;
};
