import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { TeiaSearchGroup } from '@stereograph/teia-system-design/twin-api';

export interface TeiaQuantityTakeoffContextProps {
  searchRules: TeiaSearchGroup;
  updateSearchRules: (searchRules: TeiaSearchGroup) => void;
}

const TeiaQuantityTakeoffContext = createContext<TeiaQuantityTakeoffContextProps | null>(null);

export interface TeiaQuantityTakeoffWidgetProviderProps {
  children: ReactNode;
  searchRules: TeiaSearchGroup;
}

export const TeiaQuantityTakeoffWidgetProvider = (
  props: TeiaQuantityTakeoffWidgetProviderProps
) => {
  const { children, searchRules } = props;
  const [currentSearchRules, setCurrentSearchRules] = useState<TeiaSearchGroup>(searchRules);
  const value = useMemo(
    () => ({
      searchRules: currentSearchRules,
      updateSearchRules: (searchRules: TeiaSearchGroup) => setCurrentSearchRules(searchRules)
    }),
    [currentSearchRules, setCurrentSearchRules]
  );
  return (
    <TeiaQuantityTakeoffContext.Provider value={value}>
      {children}
    </TeiaQuantityTakeoffContext.Provider>
  );
};

export const useTeiaQuantityTakeoffContext = () => {
  const context = useContext(TeiaQuantityTakeoffContext);
  if (context === null) {
    throw new Error(
      'useTeiaQuantityTakeoffContext must be used within a TeiaQuantityTakeoffWidgetProvider'
    );
  }
  return context;
};
