import { Button, Stack } from '@stereograph/teia-system-design';
import {
  LoadTwinCommand,
  TeiaViewerSourceType,
  useTeiaSource,
  useTeiaViewerContext
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { SaveLoadAsSelection } from '../components/SaveLoadAsSelection';
import { computeSearch } from '../utils/computeSearch';
import { getModelsByIds } from '../utils/getModelsByIds';
import { getSelectionsByIds } from '../utils/getSelectionsByIds';
import { PartialLoading } from './TeiaPartialLoadingWidgetContent';
import { useTeiaPartialLoadingContext } from './TeiaPartialLoadingWidgetProvider';

export const TeiaPartialLoadingWidgetFooter = () => {
  const { viewer } = useTeiaViewerContext();
  const { handleSubmit, watch } = useFormContext<PartialLoading>();

  const selectedModels = watch('models');
  const selectedSelectionFilters = watch('selections');
  const canSubmit = selectedModels.length > 0 || selectedSelectionFilters.length > 0;

  const { selections, models } = useTeiaPartialLoadingContext();
  const { t } = usePluginTranslation();

  const twin = useTeiaSource(TeiaViewerSourceType.Twin);
  if (!twin) {
    return null;
  }

  const onLoadInTwin: SubmitHandler<PartialLoading> = (data) => {
    const selectionsToLoad = getSelectionsByIds(data.selections, selections);
    const modelsToLoad = getModelsByIds(data.models, models);

    const filter = computeSearch(selectionsToLoad, modelsToLoad);
    const { projectId, type } = twin;
    const command = new LoadTwinCommand(viewer, projectId, type, filter);
    command.execute();
  };
  return (
    <Stack direction={'row'} spacing={4}>
      <SaveLoadAsSelection />
      <Button type={'submit'} onClick={handleSubmit(onLoadInTwin)} disabled={!canSubmit}>
        {t('partial_loading_plugin.form.button_load.label')}
      </Button>
    </Stack>
  );
};
