import { PluginBuilder, TeiaViewer } from '@stereograph/teiaviewer';
import { setupPluginsTranslation } from '@translation';
import { SaveTeiaObjectsSelection, ShowTeiaSelectionRecordWidget } from './ToolbarItems';
import { TeiaSelectionInfoWidget } from './widgets/TeiaSelectionInfoWidget';
import { TeiaSelectionRecordWidget } from './widgets/TeiaSelectionRecordWidget';

declare global {
  interface ViewerPluginRegistry {
    selectionInfo: TeiaSelectionInfoPlugin;
  }
}

const PluginBaseClass = PluginBuilder.NewPlugin('SelectionInfo');

/**
 * SelectionInfoPlugin is dependant from QTOPlugin as it uses the exported toolbar items
 */
export class TeiaSelectionInfoPlugin extends PluginBaseClass<TeiaViewer> {
  static override readonly ToolbarItems = {
    SaveTeiaObjectsSelection,
    ShowTeiaSelectionRecordWidget
  };

  override dependencies() {
    return ['qto', 'openData', 'patchData'] as const;
  }

  override onRegister(viewer: TeiaViewer): void {
    setupPluginsTranslation();
    this._setupWidgets(viewer);
  }

  private _setupWidgets(viewer: TeiaViewer) {
    viewer.widgetFactory.registerWidget('SelectionInfo', () => {
      return new TeiaSelectionInfoWidget(viewer, {
        x: 20,
        y: 50
      });
    });

    viewer.widgetFactory.registerWidget('TeiaSelectionRecord', ({ viewportRect }) => {
      const { width, height } = viewportRect;
      return new TeiaSelectionRecordWidget(viewer, {
        x: width - width / 3 - 5,
        y: 5,
        width: width / 3,
        height: height - 10,
        minWidth: 400,
        minHeight: 400
      });
    });
  }
}
