import { Viewer } from '@stereograph/teiaviewer';
import { buildAnomalyGuidsDictionnary } from '../widgets/utils';

export const useFormatAnomalyGuids = (viewer: Viewer) => {
  const selection = viewer.scene.objectSelection;

  const selectionGuids = Array.from(selection.getUuids());
  const visibleGuids = Array.from(viewer.scene.getAllVisibleObjectUuids());
  const hiddenGuids = Array.from(viewer.scene.getAllInvisibleObjectUuids());

  const anomalyGuids = buildAnomalyGuidsDictionnary(selectionGuids, visibleGuids, hiddenGuids);

  return anomalyGuids;
};
