import { MRT_SortingState } from '@stereograph/teia-system-design';
import { Alert } from '@stereograph/teia-system-design/deprecated';
import {
  AnomalyOrderableFields,
  AnomalyPriority,
  AnomalyStatus,
  AnomalyType,
  GetAnomaliesQueryParams
} from '@stereograph/teia-system-design/twin-api';
import { TeiaViewer, useViewerContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useMemo, useState } from 'react';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { TeiaAnomalyResumeTable } from '../components/TeiaAnomalyResumeTable';
import { useAnomalyContext } from '../context/AnomalyContext';

export type ColumnFilter =
  | { id: 'type'; value: AnomalyType }
  | { id: 'priority'; value: AnomalyPriority }
  | { id: 'status'; value: AnomalyStatus }
  | { id: 'author'; value: string }
  | { id: 'title'; value: string }
  | { id: 'isExported'; value: boolean };

export const TeiaAnomalyResumeWidgetContent = () => {
  const { viewer } = useViewerContext<TeiaViewer>();
  const source = viewer.currentSource;
  const { t } = usePluginTranslation();

  if (!source) {
    return <Alert variant="info">{t('anomaly_plugin.null_source.text')}</Alert>;
  }

  return (
    <div className="st-flex st-flex-col st-h-full st-w-full st-overflow-auto">
      <div className="st-h-full st-overflow-auto">
        <TeiaAnomalyResume />
      </div>
    </div>
  );
};

const TeiaAnomalyResume = () => {
  const { projectId, fileIds } = useAnomalyContext();
  const { getAnomalies } = useAnomalyApi(projectId);
  const { t } = usePluginTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<Array<ColumnFilter>>([]);

  const params: GetAnomaliesQueryParams = useMemo(() => {
    return {
      pageNumber: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      fileIds,
      status: columnFilters.find((item) => item.id === 'status')?.value as AnomalyStatus,
      type: columnFilters.find((item) => item.id === 'type')?.value as AnomalyType,
      priority: columnFilters.find((item) => item.id === 'priority')?.value as AnomalyPriority,
      author: columnFilters.find((item) => item.id === 'author')?.value as string,
      title: columnFilters.find((item) => item.id === 'title')?.value as string,
      orderBy: sorting[0]?.id ? (sorting[0].id as AnomalyOrderableFields) : 'id',
      orderDirection: sorting[0] && !sorting[0].desc ? 'asc' : 'desc'
    };
  }, [pagination, fileIds, columnFilters, sorting]);

  const { data: anomalies, isLoading } = getAnomalies(params);

  if (!anomalies && !isLoading) {
    return <Alert variant="info">{t('anomaly_plugin.resume_widget.no_data.text')}</Alert>;
  }

  return (
    <TeiaAnomalyResumeTable
      anomalies={anomalies?.data ?? []}
      rowCount={anomalies?.totalRecords ?? 0}
      pagination={pagination}
      setPagination={setPagination}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      sorting={sorting}
      setSorting={setSorting}
      isLoading={isLoading}
    />
  );
};
