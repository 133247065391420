import FileUploadIcon from '@resources/icons/FileUploadIcon.svg?react';
import { FileSource, TeiaViewerSourceType, ToolbarItem, TwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useRef } from 'react';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { ACCEPTED_ANOMALY_FILE_TYPE } from '../widgets/types';
import { defineFileImportUrl } from '../widgets/utils';

interface UploadAnomalyToolbarItemProps {
  source: TwinSource | FileSource;
}

export const UploadAnomalyToolbarItem = (props: UploadAnomalyToolbarItemProps) => {
  const { source } = props;
  const { t } = usePluginTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const twinProjectType = source.sourceType === TeiaViewerSourceType.Twin ? source.type : 'Draft';

  const { importAnomaly } = useAnomalyApi(source.projectId);

  const handleFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    const fileExtension = files[0].name.split('.').pop();

    if (!fileExtension) {
      return;
    }

    importAnomaly(files[0], twinProjectType, defineFileImportUrl(fileExtension));
    event.target.value = '';
  };

  return (
    <>
      <ToolbarItem
        hidden={source === null}
        icon={{ svgIcon: FileUploadIcon }}
        tooltip={t('anomaly_plugin.info_widget.upload.tooltip')}
        onClick={handleFileClick}
      />
      <input
        className={'st-sr-only'}
        type="file"
        accept={ACCEPTED_ANOMALY_FILE_TYPE.join(',')}
        id="upload-anomaly"
        hidden
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </>
  );
};
