import { Stack } from '@stereograph/teia-system-design';
import { TeiaViewerSourceType, useTeiaSource } from '@stereograph/teiaviewer';
import { ModelRecords } from '../components/ModelRecords';
import { SelectionRecords } from '../components/SelectionRecords';

export interface PartialLoading {
  models: Array<string>;
  selections: Array<string>;
  name?: string;
}

export const TeiaPartialLoadingWidgetContent = () => {
  const twin = useTeiaSource(TeiaViewerSourceType.Twin);
  if (!twin) {
    return null;
  }

  return (
    <Stack
      component={'form'}
      spacing={4}
      sx={{
        height: '100%'
      }}
    >
      <SelectionRecords />
      <ModelRecords />
    </Stack>
  );
};
