import { Model, SelectionFilter, TeiaSearchRule } from '@stereograph/teia-system-design/twin-api';
import { formartModelToSearchRule } from './formatModelToSearchRule';

export const computeSearch = (selections: Array<SelectionFilter>, models: Array<Model>) => {
  return {
    condition: 'Or',
    rules: [
      ...selections.map((selection) => selection.filter as TeiaSearchRule),
      ...models.map(formartModelToSearchRule)
    ]
  } as TeiaSearchRule;
};
