import PatchDataIcon from '@resources/icons/PatchData.svg?react';
import {
  OpenWidgetCommand,
  TeiaViewer,
  TeiaViewerSourceType,
  ToolbarItem,
  useTeiaSource,
  useViewerContext,
  useViewerSelection
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';

export const ShowTeiaPatchDataWidget = () => {
  const { t } = usePluginTranslation();
  const { viewer } = useViewerContext<TeiaViewer>();
  const twin = useTeiaSource(TeiaViewerSourceType.Twin);
  const { selection } = useViewerSelection();

  const onClick = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaPatchData');
    command.execute();
  };

  return (
    <ToolbarItem
      hidden={!twin}
      icon={{ svgIcon: PatchDataIcon }}
      onClick={onClick}
      disabled={selection.length === 0}
      label={t('toolbar.patch_data.tooltip')}
    />
  );
};
