import { Box, Divider, Stack, Text } from '@stereograph/teia-system-design';
import { AnomalyComment } from '@stereograph/teia-system-design/twin-api';
import { usePluginTranslation } from '@translation';
import { DeleteAnomalyCommentForm } from './DeleteAnomalyCommentForm';

interface TeiaAnomalyCommentListProps {
  anomalyId: number;
  comments: Array<AnomalyComment>;
}

export const TeiaAnomalyCommentList = (props: TeiaAnomalyCommentListProps) => {
  const { comments, anomalyId } = props;
  const { t } = usePluginTranslation();

  return (
    <>
      {comments.map((comment) => (
        <Box
          key={`comment-${comment.id}-${window.crypto.randomUUID()}`}
          sx={{
            paddingBottom: 4
          }}
        >
          <Stack
            direction="row"
            spacing={4}
            sx={{
              paddingBottom: 4
            }}
          >
            <Stack direction="column" sx={{ width: '100%' }} spacing={8}>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <Text className="st-text-md st-font-semibold">{`${comment.author}`}</Text>
                  <Text>
                    {t('date', {
                      val: new Date(comment.creationDate),
                      formatParams: {
                        val: {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric'
                        }
                      }
                    })}
                  </Text>
                </Stack>
                <DeleteAnomalyCommentForm commentId={comment.id} anomalyId={anomalyId} />
              </Stack>
              <Text>{`${comment.comment}`}</Text>
            </Stack>
          </Stack>
          <Divider />
        </Box>
      ))}
    </>
  );
};
