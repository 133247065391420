import {
  StandardWidget,
  StandardWidgetOptions,
  TeiaViewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { AnomalyContextProvider } from '../context/AnomalyContext';
import { TeiaCreateAnomalyWidgetContent } from './TeiaCreateAnomalyWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    TeiaCreateAnomaly: WidgetFactoryFunction<TeiaCreateAnomalyWidget, { projectId: number }>;
  }
}

export class TeiaCreateAnomalyWidget extends StandardWidget {
  readonly type = 'TeiaCreateAnomaly';
  projectId: number;

  constructor(viewer: TeiaViewer, projectId: number, options: StandardWidgetOptions = {}) {
    super(viewer, {
      ...options,
      color: 'info'
    });

    this.projectId = projectId;
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return t('anomaly_plugin.widget.creation_title');
  };

  override Content = () => {
    return (
      <AnomalyContextProvider projectId={this.projectId}>
        <TeiaCreateAnomalyWidgetContent />
      </AnomalyContextProvider>
    );
  };

  override Footer = () => {
    return null;
  };
}
