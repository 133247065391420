import {
  StandardWidget,
  StandardWidgetOptions,
  Viewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { TeiaSelectionRecordWidgetContent } from './TeiaSelectionRecordWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    TeiaSelectionRecord: WidgetFactoryFunction<TeiaSelectionRecordWidget>;
  }
}

export class TeiaSelectionRecordWidget extends StandardWidget {
  readonly type = 'TeiaSelectionRecord';

  constructor(viewer: Viewer, options: StandardWidgetOptions = {}) {
    if (!viewer.viewport) {
      throw new Error('No Viewport attached to the viewer');
    }

    super(viewer, {
      ...options,
      color: 'info'
    });
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return (
      <div className="st-w-full st-h-full st-uppercase">
        {t('selection_plugin.record_widget.selection_list.title')}
      </div>
    );
  };

  override Content = () => {
    return <TeiaSelectionRecordWidgetContent />;
  };

  override Footer = () => {
    return null;
  };
}
