import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Text
} from '@stereograph/teia-system-design';
import { Anomaly } from '@stereograph/teia-system-design/twin-api';
import { usePluginTranslation } from '@translation';
import { useAnomalyApi } from '../../api/useAnomalyApi';
import { TeiaAnomalyHistoryItem } from './TeiaAnomalyHistoryItem';

interface TeiaAnomalyHistoryProps {
  anomaly: Anomaly;
}

export const TeiaAnomalyHistory = (props: TeiaAnomalyHistoryProps) => {
  const { anomaly } = props;
  const { t } = usePluginTranslation();
  const { getAnomalyHistory } = useAnomalyApi(anomaly.projectId);
  const { data: history } = getAnomalyHistory(anomaly.id);

  if (!history) {
    return null;
  }

  return (
    <Box key="anomaly-history-accordion">
      <Accordion color="white">
        <AccordionSummary>
          <Text variant="lead">{t('anomaly_plugin.widget.history.title')}</Text>
        </AccordionSummary>
        <AccordionDetails>
          <TeiaAnomalyHistoryItem history={history.data} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
