import {
  Command,
  GroupCommand,
  NotUndoableCommandError,
  SetAllObjectsVisibleCommand,
  SetObjectsVisibilityCommand,
  Viewer
} from '@stereograph/teiaviewer';

/**
 * @category command
 */
export class SetAllObjectVisibleAndHideObjects extends Command {
  readonly name = 'SetAllObjectVisibleAndHideObjects';
  override isUndoable = false;
  private hiddenGuids: Array<string>;

  private readonly command: GroupCommand;

  constructor(viewer: Viewer, hiddenGuids: Array<string>) {
    super(viewer);
    this.hiddenGuids = hiddenGuids;

    this.command = new GroupCommand(viewer, [
      new SetAllObjectsVisibleCommand(this.viewer),
      new SetObjectsVisibilityCommand(this.viewer, this.hiddenGuids, false)
    ]);
  }

  protected override async _do(): Promise<void> {
    this.command.execute({ addToUndoHistory: false, requestRender: false });
  }
  protected override async _undo(): Promise<void> {
    throw new NotUndoableCommandError(this);
  }
}
