import { Bold, Divider, Stack, Text, TextField } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { useTeiaPartialLoadingContext } from '../widgets/TeiaPartialLoadingWidgetProvider';
import { SelectionCheckbox } from './SelectionCheckbox';

export const SelectionRecords = () => {
  const { selections } = useTeiaPartialLoadingContext();
  const { t } = usePluginTranslation();
  const [selectionSearch, setSelectionSearch] = useState<string>('');
  const filteredSelections = selections.filter((selection) => {
    return selection.name.toLowerCase().includes(selectionSearch.toLowerCase());
  });
  return (
    <Stack
      sx={{
        height: '50%'
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          p: 2
        }}
      >
        <Text>
          <Bold>{t('partial_loading_plugin.form.selections.label')}</Bold>
        </Text>
        <TextField
          label={t('partial_loading_plugin.form.search.label')}
          value={selectionSearch}
          onChange={(event) => setSelectionSearch(event.target.value)}
        />
      </Stack>
      <Divider />
      <div className="st-h-full st-overflow-y-auto st-pl-2 st-bg-gray-50">
        <Stack>
          {filteredSelections.map((selection) => (
            <SelectionCheckbox key={selection.id} selection={selection} />
          ))}
          {filteredSelections.length === 0 && (
            <Text
              sx={{
                pt: 2
              }}
            >
              {selectionSearch.length === 0 && t('partial_loading_plugin.form.no_selection.text')}
              {selectionSearch.length > 0 &&
                t('partial_loading_plugin.form.no_selection_for_search.text', {
                  search: selectionSearch
                })}
            </Text>
          )}
        </Stack>
      </div>
    </Stack>
  );
};
