import { Button } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { useAnomalyContext } from '../context/AnomalyContext';

interface DeleteAnomalyCommentFormProps {
  commentId: number;
  anomalyId: number;
}

interface FormValues {
  commentId: number;
}

export const DeleteAnomalyCommentForm = (props: DeleteAnomalyCommentFormProps) => {
  const { commentId, anomalyId } = props;
  const { projectId } = useAnomalyContext();
  const { deleteAnomalyComment } = useAnomalyApi(projectId);
  const { t } = usePluginTranslation();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      commentId: commentId
    }
  });

  const { handleSubmit } = methods;

  const onDeleteComment: SubmitHandler<FormValues> = (data: FormValues) => {
    deleteAnomalyComment({ anomalyId, commentId: data.commentId });
  };

  return (
    <form onSubmit={handleSubmit(onDeleteComment)}>
      <Button type="submit" color="error" sx={{ marginLeft: 'auto' }}>
        {t('anomaly_plugin.widget.comment_form.submit.delete.label')}
      </Button>
    </form>
  );
};
