import { Divider, Stack, Text } from '@stereograph/teia-system-design';
import {
  AnomalyHistory,
  AnomalyHistoryProperty,
  AnomalyHistoryStatus
} from '@stereograph/teia-system-design/twin-api';
import { TeiaAnomalyHistoryItemAdded } from './TeiaAnomalyHistoryItemAdded';
import { TeiaAnomalyHistoryItemDeleted } from './TeiaAnomalyHistoryItemDeleted';
import { TeiaAnomalyHistoryItemUpdated } from './TeiaAnomalyHistoryItemUpdated';

interface TeiaAnomalyHistoryItemProps {
  history: Array<AnomalyHistory>;
}

export const TeiaAnomalyHistoryItem = (props: TeiaAnomalyHistoryItemProps) => {
  const { history } = props;

  const renderAnomalyItemByState = (
    anomalyHistoryState: string,
    property: AnomalyHistoryProperty,
  ) => {
    switch (anomalyHistoryState) {
      case AnomalyHistoryStatus.ADDED:
        return (
          <TeiaAnomalyHistoryItemAdded
            property={property}
            key={property.propertyName}
          />
        );
      case AnomalyHistoryStatus.UPDATED:
        return (
          <TeiaAnomalyHistoryItemUpdated
            property={property}
            key={property.propertyName}
          />
        );
      case AnomalyHistoryStatus.DELETED:
        return (
          <TeiaAnomalyHistoryItemDeleted
            property={property}
            key={property.propertyName}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack divider={<Divider flexItem />} spacing={2}>
      {

        history.map((item) => (
          <Stack key={item.anomalyId} spacing={2} sx={{ py: 2 }}>
            <Stack direction="row" spacing={2}>
              <Text bold>{item.author}</Text>
              <Text>{new Date(item.creationDate).toLocaleString('fr-FR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })}
              </Text>
            </Stack>
            {item.anomalyHistoryPropertyDtos.map((property) => (
              renderAnomalyItemByState(item.anomalyHistoryState, property)
            ))}
          </Stack>
        ))
      }
    </Stack>
  );
};
