import { TeiaSearchCondition, TeiaSearchFilter } from '@stereograph/teia-system-design/twin-api';

export interface PatchDataAutoCompleteOption {
  value: string,
  label: string
}

export const createGuidRules = (guids: Iterable<string>): TeiaSearchFilter => {
  const rules = new Array<TeiaSearchCondition>();

  for (const guid of guids) {
    if (guid !== '') {
      rules.push({
        type: 'Guid',
        operator: 'EqualTo',
        value: guid
      });
    }
  }

  return {
    condition: 'Or',
    rules: rules
  };
};

export const createOption = (value: string, label?: string) => {
  return {
    value,
    label: label ?? value
  };
};
