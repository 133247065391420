import { TwinType } from '@stereograph/teia-system-design/twin-api';
import { TeiaViewerSourceType, useTeiaViewerContext } from '@stereograph/teiaviewer';
import { createContext, useContext, useMemo } from 'react';
import { useTwinTransformersApi } from '../api/useTwinTransformersApi';

export interface AnomalyContext {
  projectId: number;
  twinProjectType: TwinType;
  fileIds: Array<number>;
}

export interface AnomalyContextProviderProps {
  projectId: number;
  children: React.ReactNode;
}

const Context = createContext<AnomalyContext | null>(null);

export const AnomalyContextProvider = (props: AnomalyContextProviderProps) => {
  const { projectId, children } = props;
  const { viewer } = useTeiaViewerContext();
  const { getTwinFiles } = useTwinTransformersApi(projectId);
  const { data: twinFiles, isLoading } = getTwinFiles({ pageNumber: 1, pageSize: 1000 });

  const fileIds =
    viewer.currentSource?.sourceType === TeiaViewerSourceType.File
      ? [viewer.currentSource?.fileId]
      : twinFiles;

  const twinProjectType =
    viewer.currentSource?.sourceType === TeiaViewerSourceType.Twin
      ? viewer.currentSource?.type
      : 'Draft';

  const context = useMemo(
    () => ({
      projectId,
      twinProjectType,
      fileIds: fileIds
    }),
    [projectId, twinProjectType, fileIds]
  );

  if (isLoading) {
    return null;
  }

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useAnomalyContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Anomaly context not found');
  }

  return context;
};
