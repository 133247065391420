import {
  CameraGizmo,
  Command,
  LoadFileCommand,
  LoadTwinCommand,
  TeiaToolbar,
  TeiaViewer,
  TeiaViewport
} from '@stereograph/teiaviewer';
import {
  OpenDataPlugin,
  PartialLoadingPlugin,
  PatchDataPlugin,
  QuantityTakeOffPlugin,
  TeiaAnomalyPlugin,
  TeiaSelectionInfoPlugin
} from '@stereograph/teiaviewer-plugins';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { ShowDevToolsWidgetBhavior } from './behaviors/ShowDevToolsWidgetBehavior';
import { CustomDevToolsWidget } from './debug/CustomDevToolsWidget';
import { Environment, TeiaAuthProvider, useTeiaAuth } from './utils/TeiaAuthentication';

const viewer = new TeiaViewer({
  verbose: true,
  debug: true
});
viewer.addBehavior(new ShowDevToolsWidgetBhavior(viewer));
viewer.registerPlugin('qto', new QuantityTakeOffPlugin());
viewer.registerPlugin('patchData', new PatchDataPlugin());
viewer.registerPlugin('openData', new OpenDataPlugin());
viewer.registerPlugin('selectionInfo', new TeiaSelectionInfoPlugin());
viewer.registerPlugin('partialLoading', new PartialLoadingPlugin());
viewer.registerPlugin('anomaly', new TeiaAnomalyPlugin());

// Override the default DevTools widget
viewer.widgetFactory.registerWidget('DevTools', ({ viewportRect }) => {
  return new CustomDevToolsWidget(viewer, {
    width: CustomDevToolsWidget.DEFAULT_WIDTH,
    x: viewportRect.width - CustomDevToolsWidget.DEFAULT_WIDTH - 5,
    y: 5
  });
});

/**
 * App component setting up a viewer and a viewport
 * @returns
 */
export const App = () => {
  return (
    <div className="st-h-screen st-w-screen">
      <Toaster
        position="top-right"
        toastOptions={{
          loading: { duration: Infinity },
          duration: 2500,
          style: { overflow: 'hidden' }
        }}
      />
      <TeiaAuthProvider defaultEnvironment={Environment.Develop}>
        <AutoLoadScene />
        <TeiaViewport viewer={viewer}>
          <CameraGizmo />
          <TeiaToolbar className="st-absolute st-bottom-3 st-left-1/2 -st-translate-x-1/2">
            <QuantityTakeOffPlugin.ToolbarItems.ShowQTOWidget />
            <PatchDataPlugin.ToolbarItems.ShowTeiaPatchDataWidget />
            <TeiaAnomalyPlugin.ToolbarItems.ShowTeiaAnomaly />
            <TeiaSelectionInfoPlugin.ToolbarItems.ShowTeiaSelectionRecordWidget />
            <PartialLoadingPlugin.ToolbarItems.LoadPartialTwin />
          </TeiaToolbar>
        </TeiaViewport>
      </TeiaAuthProvider>
    </div>
  );
};

const AutoLoadScene = () => {
  const executeCommandsOnce = React.useRef(false);
  const { isAuthenticated } = useTeiaAuth();

  React.useEffect(() => {
    if (isAuthenticated) {
      if (!executeCommandsOnce.current) {
        executeCommandsOnce.current = true;
        setTimeout(() => {
          autoLoadTwinOrFile();
        }, 300);
      }
    }
  }, [isAuthenticated]);

  /**
   * Automatically loads a Twin or a File if there is projectId={pid}/fileId={fid} in the url.
   * @returns
   */
  const autoLoadTwinOrFile = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get('projectId');
    const fileId = urlParams.get('fileId');
    if (projectId !== null) {
      let cmd: Command = new LoadTwinCommand(viewer, Number(projectId), 'Draft');
      if (fileId !== null) {
        cmd = new LoadFileCommand(viewer, Number(projectId), Number(fileId));
      }
      cmd.execute();
    }
  };

  return <></>;
};
