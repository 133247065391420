export const ACCEPTED_ANOMALY_FILE_TYPE = [
  '.bcf',
  '.bcfzip',
  '.xls',
  '.xlsx',
];

export enum AnomalyImportErrorCode {
  GuidNotFound = 'guid_not_found',
  WrongType = 'type_is_wrong',
  WrongPriority = 'priority_is_wrong',
  WrongStatus = 'status_is_wrong',
  IdNotFound = 'id_not_found',
}