import {
  fetchAllPages,
  GetModelsQueryParams,
  Model,
  ModelApi
} from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';

export const useModelApi = (projectId: number) => {
  const { viewer } = useTeiaViewerContext();
  const pageSize = 1000;
  const useGetAllModels = (params?: GetModelsQueryParams) => {
    return useQuery({
      queryKey: ['Models', projectId, 'all', params],
      queryFn: async () => {
        const modelApiCall = (pageNumber: number, pageSize: number) =>
          ModelApi(viewer.twinApiClient).getModels(projectId, {
            ...params,
            pageNumber: pageNumber,
            pageSize: pageSize
          });
        return await fetchAllPages<Model>(modelApiCall, 1, pageSize);
      }
    });
  };

  return {
    useGetAllModels
  };
};
