import { SelectionFilterApi } from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

const queryKeys = ['SelectionFilter'];

export const useAllSelectionFilters = () => {
  const twin = useTwinSource();
  const { projectId } = twin;
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  let pageSize = 1000;
  const { data } = useInfiniteQuery({
    queryKey: [...queryKeys, projectId],
    queryFn: ({ pageParam = 1 }) => {
      return SelectionFilterApi(client).getSelectionFilters(projectId, pageParam, pageSize);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      pageSize = lastPage.pageSize;
      const nextPage = lastPage.pageNumber + 1;
      if (nextPage > lastPage.totalPages) {
        return undefined;
      }
      return nextPage;
    },
    placeholderData: keepPreviousData
  });

  return data?.pages.flatMap((page) => page.data).sort((a, b) => a.name.localeCompare(b.name));
};
