import { BooleanController, PluginBuilder, TeiaViewer } from '@stereograph/teiaviewer';
import { setupPluginsTranslation } from '@translation';
import { LoadPartialTwin } from './ToolbarItems';
import { TeiaPartialLoadingWidget } from './widgets/TeiaPartialLoadingWidget';

declare global {
  interface ViewerPluginRegistry {
    partialLoading: PartialLoadingPlugin;
  }
}

const Settings = () => ({
  enabled: new BooleanController(true)
});

const PluginBaseClass = PluginBuilder.NewPlugin('PartialLoading', Settings);

export class PartialLoadingPlugin extends PluginBaseClass<TeiaViewer> {
  static override readonly ToolbarItems = { LoadPartialTwin };

  override onRegister(viewer: TeiaViewer): void {
    setupPluginsTranslation();
    this._setupWidgets(viewer);
  }

  private _setupWidgets(viewer: TeiaViewer) {
    viewer.widgetFactory.registerWidget('TeiaPartialLoading', () => {
      return new TeiaPartialLoadingWidget(viewer, {});
    });
  }
}
