import {
  Box,
  createMRTColumnHelper,
  MRT_SortingState,
  MRT_TableOptions,
  Table
} from '@stereograph/teia-system-design';
import { Alert } from '@stereograph/teia-system-design/deprecated';
import {
  SelectionFilter,
  SelectionFilterType,
  SelectionSortOrder
} from '@stereograph/teia-system-design/twin-api';
import { useTwinSource } from '@stereograph/teiaviewer';
import { ColumnFiltersState } from '@tanstack/react-table';
import { usePluginTranslation } from '@translation';
import { useMemo, useState } from 'react';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';
import { SectionVisibilityCell } from '../components/SectionVisibilityCell';
import { SelectionActionsCell } from '../components/SelectionActionsCell';
import { SelectionTypeCell } from '../components/SelectionTypeCell';

export const TeiaSelectionRecordWidgetContent = () => {
  const { t } = usePluginTranslation();
  const twin = useTwinSource();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'name', desc: false }]);

  const typeFilter = useMemo(() => {
    const filter = columnFilters.find((filter) => filter?.id === 'type');
    return filter?.value as SelectionFilterType | undefined;
  }, [columnFilters]);

  const nameFilter = useMemo(() => {
    const filter = columnFilters.find((filter) => filter?.id === 'name');
    return filter?.value as string | undefined;
  }, [columnFilters]);

  const visibilityFilter = useMemo(() => {
    const filter = columnFilters.find((filter) => filter?.id === 'isPublic');
    if (!filter) {
      return undefined;
    }
    return (filter?.value === 'public') as boolean | undefined;
  }, [columnFilters]);

  const [sortField, sortOrder] = useMemo(() => {
    if (sorting.length === 0) {
      return [undefined, undefined];
    }
    const sortField = sorting[0].id;
    const sortOrder = sorting[0].desc ? SelectionSortOrder.DESC : SelectionSortOrder.ASC;
    return [sortField, sortOrder];
  }, [sorting]);

  const { useGetSelectionFilters } = useSelectionFiltersApi(twin.projectId);
  const { data, isLoading } = useGetSelectionFilters(
    pagination.pageIndex + 1,
    pagination.pageSize,
    {
      name: nameFilter,
      type: typeFilter,
      isPublic: visibilityFilter,
      sortField: sortField,
      sortOrder: sortOrder
    }
  );

  const columnHelper = createMRTColumnHelper<SelectionFilter>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: t('selection_plugin.record_widget.name.title')
      }),
      columnHelper.accessor('type', {
        header: t('selection_plugin.record_widget.type.title'),
        Cell: SelectionTypeCell,
        filterVariant: 'select',
        filterSelectOptions: [
          {
            value: SelectionFilterType.STATIC,
            label: t('selection_plugin.record_widget.selection_list.static_filter.tooltip')
          },
          {
            value: SelectionFilterType.DYNAMIC,
            label: t('selection_plugin.record_widget.selection_list.dynamic_filter.tooltip')
          }
        ]
      }),
      columnHelper.accessor('isPublic', {
        header: t('selection_plugin.record_widget.is_public.title'),
        Cell: SectionVisibilityCell,
        filterVariant: 'select',
        filterSelectOptions: [
          {
            value: 'private',
            label: t('selection_plugin.record_widget.is_public.cell.private.text')
          },
          {
            value: 'public',
            label: t('selection_plugin.record_widget.is_public.cell.public.text')
          }
        ]
      }),
      columnHelper.display({
        size: 100,
        id: 'actions',
        header: t('selection_plugin.record_widget.actions.title'),
        Cell: SelectionActionsCell,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: 'right'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        muiTableFooterCellProps: {
          align: 'right'
        }
      })
    ];
  }, [t]);

  if (!data && !isLoading) {
    return (
      <Alert variant="info">{t('selection_plugin.record_widget.selection_list.nodata.text')}</Alert>
    );
  }

  const tableOptions: MRT_TableOptions<SelectionFilter> = {
    columns,
    data: data?.data ?? [],
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount: data?.totalRecords ?? 0,
    pageCount: data?.totalPages ?? 0,
    enableSorting: true,
    enableGlobalFilter: false,
    enableHiding: false,
    enableMultiSort: false,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    initialState: {
      showColumnFilters: true
    },
    state: {
      columnFilters,
      pagination,
      isLoading,
      sorting
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
        overflow: 'auto'
      }
    }),
    muiTablePaperProps: {
      sx: {
        height: '100%'
      }
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto'
      }}
    >
      <Table tableOptions={tableOptions} />
    </Box>
  );
};
