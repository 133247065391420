import { Stack } from '@stereograph/teia-system-design';
import { Spinner } from '@stereograph/teia-system-design/deprecated';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { AnomalySceneBanner } from '../components/AnomalySceneBanner';
import { TeiaAnomalyHistory } from '../components/history/TeiaAnomalyHistory';
import { TeiaAnomalyComment } from '../components/TeiaAnomalyComment';
import { TeiaUpdateAnomalyForm } from '../components/TeiaUpdateAnomalyForm';
import { useAnomalyContext } from '../context/AnomalyContext';

interface TeiaUpdateAnomalyWidgetContentProps {
  anomalyId: number;
}

export const TeiaUpdateAnomalyWidgetContent = (props: TeiaUpdateAnomalyWidgetContentProps) => {
  const { anomalyId } = props;
  const { projectId } = useAnomalyContext();
  const { getAnomaly } = useAnomalyApi(projectId);
  const { data: anomaly, isLoading } = getAnomaly(anomalyId);

  if (isLoading) {
    return <Spinner />;
  }

  if (!anomaly) {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        padding: 4,
        overflowY: 'auto',
        height: '100%'
      }}
    >
      {anomaly.stateByGuid && Object.keys(anomaly.stateByGuid).length > 1 && (
        <AnomalySceneBanner anomalyGuids={anomaly.stateByGuid} />
      )}
      <TeiaUpdateAnomalyForm anomaly={anomaly} />
      <Stack direction="column" spacing={4} sx={{ mt: '1rem' }}>
        <TeiaAnomalyHistory anomaly={anomaly} />
        <TeiaAnomalyComment anomalyId={anomaly.id} />
      </Stack>
    </Stack>
  );
};
