import { Bold, Divider, Stack, Text, TextField } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { useTeiaPartialLoadingContext } from '../widgets/TeiaPartialLoadingWidgetProvider';
import { ModelCheckbox } from './ModelCheckbox';

export const ModelRecords = () => {
  const { t } = usePluginTranslation();
  const { models } = useTeiaPartialLoadingContext();

  const [modelSearch, setModelSearch] = useState<string>('');

  const filteredModels = models.filter((model) => {
    return model.name.toLowerCase().includes(modelSearch.toLowerCase());
  });

  return (
    <Stack
      sx={{
        height: '50%'
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          p: 2
        }}
      >
        <Text>
          <Bold>{t('partial_loading_plugin.form.models.label')}</Bold>
        </Text>
        <TextField
          label={t('partial_loading_plugin.form.search.label')}
          value={modelSearch}
          onChange={(event) => setModelSearch(event.target.value.trim())}
        />
      </Stack>
      <Divider />
      <div className="st-h-full st-overflow-y-auto st-pl-2 st-bg-gray-50">
        <Stack>
          {filteredModels.map((model) => (
            <ModelCheckbox model={model} key={model.ifcModelId} />
          ))}
          {filteredModels.length === 0 && (
            <Text
              sx={{
                pt: 2
              }}
            >
              {modelSearch.length === 0 && t('partial_loading_plugin.form.no_model.text')}
              {modelSearch.length > 0 &&
                t('partial_loading_plugin.form.no_model_for_search.text', {
                  search: modelSearch
                })}
            </Text>
          )}
        </Stack>
      </div>
    </Stack>
  );
};
