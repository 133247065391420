import { TeiaSearchFilter, Twin, TwinBimDataApi } from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';

export const useTwinApi = (twin: Twin, filter: TeiaSearchFilter) => {
  const queryKeys = ['Twin'];
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;

  const useGetObjectTypes = (propertyName?: string, name?: string) =>
    useQuery({
      queryKey: queryKeys,
      queryFn: async () => {
        const twinBimDataValues = await TwinBimDataApi(client).getTwinBimDataValues(
          twin,
          propertyName ?? 'Element Specific',
          name ?? 'Type',
          filter
        );
        return twinBimDataValues.map((twinBimData) => twinBimData.value);
      }
    });

  const useGetTwinObjectPropertyNames = () =>
    useQuery({
      queryKey: [...queryKeys, 'propertyNames'],
      queryFn: async () => {
        const twinBimDataPropertyNames = await TwinBimDataApi(client).getTwinBimDataPropertyNames(
          twin,
          filter
        );
        return twinBimDataPropertyNames.map((propertyName) => propertyName.value);
      }
    });

  const useGetTwinObjectNames = (propertyName = '') =>
    useQuery({
      queryKey: [...queryKeys, 'names', propertyName],
      queryFn: async () => {
        const twinBimDataNames = await TwinBimDataApi(client).getTwinBimDataNames(
          twin,
          propertyName,
          filter
        );
        return twinBimDataNames.map((name) => name.value);
      }
    });

  return {
    useGetObjectTypes,
    useGetTwinObjectPropertyNames,
    useGetTwinObjectNames
  };
};
