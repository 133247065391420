import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { ColumnAggregateVisibility } from './TeiaQuantityTakeoffTable';

export interface AggregateVisibilityContextType {
  columnsAggregateVisibility: Record<string, ColumnAggregateVisibility>;
  setColumnsAggregateVisibility: (columns: Record<string, ColumnAggregateVisibility>) => void;
}

const AggregateVisibilityContext = createContext<AggregateVisibilityContextType | null>(null);

interface AggregateVisibilityProviderProps {
  columns: Array<string>;
  children: ReactNode;
}

const formatColumnVisibility = (columns: Array<string>) => {
  const columnsVisibility: Record<string, ColumnAggregateVisibility> = {};
  for (const column of columns) {
    columnsVisibility[column] = {
      count: false,
      min: false,
      max: false,
      sum: true,
      average: false
    };
  }
  return columnsVisibility;
};

export const AggregateVisibilityProvider = (props: AggregateVisibilityProviderProps) => {
  const { columns } = props;
  const [columnsAggregateVisibility, setColumnsAggregateVisibility] = useState<
    Record<string, ColumnAggregateVisibility>
  >(formatColumnVisibility(columns));

  const values = useMemo(
    () => ({
      columnsAggregateVisibility,
      setColumnsAggregateVisibility
    }),
    [columnsAggregateVisibility, setColumnsAggregateVisibility]
  );

  useEffect(() => {
    setColumnsAggregateVisibility(formatColumnVisibility(columns));
  }, [columns]);

  return <AggregateVisibilityContext.Provider value={values} {...props} />;
};

export const useAggregateVisibility = () => {
  const aggregateVisibilityContext = useContext(AggregateVisibilityContext);
  if (!aggregateVisibilityContext) {
    throw new Error('useAggregateVisibility must be used within a AggregateVisibilityContext');
  }
  return aggregateVisibilityContext;
};
