import CreateAnomalyIcon from '@resources/icons/CreateAnomalyIcon.svg?react';
import { Button, MRT_TableInstance, Stack } from '@stereograph/teia-system-design';
import { Anomaly, AnomalyFileType } from '@stereograph/teia-system-design/twin-api';
import { OpenWidgetCommand, useTeiaViewerContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useAnomalyContext } from '../../context/AnomalyContext';
import { useExportAnomalies } from '../../hooks/useExportAnomalies';

interface TeiaAnomalyResumeTableToolbarProps {
  table: MRT_TableInstance<Anomaly>;
}

export const TeiaAnomalyResumeTableToolbar = (props: TeiaAnomalyResumeTableToolbarProps) => {
  const { table } = props;
  const { rowSelection } = table.getState();
  const { t } = usePluginTranslation();
  const { viewer } = useTeiaViewerContext();
  const { projectId } = useAnomalyContext();
  const anomalyIds = Object.keys(rowSelection).map((key) => parseInt(key));
  const { downloadAnomaly: downloadBcf, isDownloading: isBcfDownloading } = useExportAnomalies(
    anomalyIds,
    projectId,
    AnomalyFileType.BCF
  );
  const { downloadAnomaly: downloadExcel, isDownloading: isExcelDownloading } = useExportAnomalies(
    anomalyIds,
    projectId,
    AnomalyFileType.Excel
  );

  const openCreateAnomalyWidget = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaCreateAnomaly', { projectId });
    command.execute();
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Button
        onClick={openCreateAnomalyWidget}
        startIcon={<CreateAnomalyIcon style={{ fill: 'white' }} />}
        sx={{ color: 'white' }}
      >
        {t('anomaly_plugin.widget.create_anomaly.text')}
      </Button>
      <Button
        onClick={downloadBcf}
        isLoading={isBcfDownloading}
        disabled={isBcfDownloading || anomalyIds.length === 0}
      >
        {t('anomaly_plugin.resume_widget.table.toolbar.export_bcf_label')}
      </Button>
      <Button
        onClick={downloadExcel}
        isLoading={isExcelDownloading}
        disabled={isExcelDownloading || anomalyIds.length === 0}
      >
        {t('anomaly_plugin.resume_widget.table.toolbar.export_xls_label')}
      </Button>
    </Stack>
  );
};
