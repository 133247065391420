import { CubeTransparentIcon } from '@heroicons/react/24/outline';
import {
  OpenWidgetCommand,
  ToolbarItem,
  useTeiaSource,
  useTeiaViewerContext
} from '@stereograph/teiaviewer';
import { useEffect, useState } from 'react';

export const LoadPartialTwin = () => {
  const { viewer } = useTeiaViewerContext();
  const plugin = viewer.getPlugin('partialLoading');
  const isPartialLoadingEnabled = plugin.settings.enabled.value;
  const [isHidden, setIsHidden] = useState(!isPartialLoadingEnabled);
  const source = useTeiaSource();

  useEffect(() => {
    const onPartialLoadingChange = (value: boolean) => {
      setIsHidden(!value);
    };

    plugin.settings.enabled.onChange.connect(onPartialLoadingChange);

    return () => {
      plugin.settings.enabled.onChange.disconnect(onPartialLoadingChange);
    };
  }, []);

  const onClick = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaPartialLoading');
    command.execute();
  };

  return (
    <ToolbarItem
      hidden={isHidden || source === null}
      icon={{ heroIcon: CubeTransparentIcon }}
      onClick={onClick}
    />
  );
};
