import { TeiaQuantityTakeoffColumn } from '../types';
import { TeiaQuantityTakeoffTableAggregateVisibilityButton } from './TeiaQuantityTakeoffTableAggregateVisibilityButton';

interface TeiaQuantityTakeoffTableHeaderProps {
  displayedColumn: TeiaQuantityTakeoffColumn;
}

export const TeiaQuantityTakeoffTableHeader = (props: TeiaQuantityTakeoffTableHeaderProps) => {
  const { displayedColumn } = props;
  return (
    <div className={'st-flex st-gap-1 st-items-center st-w-full'}>
      <div className={'st-overflow-hidden st-break-words'}>{displayedColumn.title}</div>
      <TeiaQuantityTakeoffTableAggregateVisibilityButton column={displayedColumn.value} />
    </div>
  );
};
