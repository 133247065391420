import { MRT_Row, Stack } from '@stereograph/teia-system-design';
import { Anomaly } from '@stereograph/teia-system-design/twin-api';
import { EditAnomalyButton } from './EditAnomalyButton';
import { VisualizeSelectionButton } from './VisualizeSelectionButton';

interface ActionsCellProps {
  row: MRT_Row<Anomaly>;
}

export const ActionsCell = (props: ActionsCellProps) => {
  const { row } = props;

  const anomalyGuidsSize = row.original.stateByGuid
    ? Object.keys(row.original.stateByGuid).filter((key) => key !== 'DefaultVisibility').length
    : 0;

  return (
    <Stack direction="row" alignItems={'center'} spacing={2}>
      <EditAnomalyButton anomalyId={row.original.id} />
      {row.original.stateByGuid && anomalyGuidsSize > 0 && (
        <VisualizeSelectionButton anomaly={row.original} />
      )}
    </Stack>
  );
};
