import { zodResolver } from '@hookform/resolvers/zod';
import { Anomaly, RequestPostAnomaly } from '@stereograph/teia-system-design/twin-api';
import { useWidgetContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { useAnomalyContext } from '../context/AnomalyContext';
import { AnomalyFormSchema, TeiaAnomalyForm, anomalyFormSchema } from './TeiaAnomalyForm';

interface TeiaUpdateAnomalyFormProps {
  anomaly: Anomaly;
}

export const TeiaUpdateAnomalyForm = (props: TeiaUpdateAnomalyFormProps) => {
  const { anomaly } = props;
  const { projectId } = useAnomalyContext();
  const { widget } = useWidgetContext();
  const { patchAnomaly } = useAnomalyApi(projectId);
  const mutateAnomaly = patchAnomaly(anomaly.id);
  const { t } = usePluginTranslation();

  const methods = useForm<AnomalyFormSchema>({
    resolver: zodResolver(anomalyFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      anomalySchema: {
        ...anomaly
      }
    }
  });
  const { handleSubmit } = methods;

  const onCloseAnomalyWidget = () => {
    widget.close();
  };

  const onSubmit = (data: AnomalyFormSchema) => {
    const formAnomaly: RequestPostAnomaly = {
      ...data.anomalySchema,
      snapshot: anomaly.snapshot,
      createAnomalyViewPointCommand: {
        ...anomaly.anomalyViewPointDto
      }
    };

    toast.promise(mutateAnomaly.mutateAsync({ anomaly: formAnomaly }), {
      loading: t('anomaly_plugin.widget.form.submit.loading.text'),
      success: () => {
        onCloseAnomalyWidget();
        return t('anomaly_plugin.widget.form.submit.update.success.text');
      },
      error: t('anomaly_plugin.widget.form.submit.update.error.text')
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TeiaAnomalyForm
          buttonLabel={t('anomaly_plugin.widget.form.submit.update.label')}
          snapshot={anomaly.snapshot}
        />
      </form>
    </FormProvider>
  );
};
