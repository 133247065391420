import PlaceholderImage from '@resources/icons/PlaceholderImage.svg';
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  Textarea,
  TextField
} from '@stereograph/teia-system-design';
import {
  AnomalyPriority, AnomalyStatus, AnomalyType, ObjectAnomalyInformation
} from '@stereograph/teia-system-design/twin-api';
import { usePluginTranslation } from '@translation';
import { Controller, useFormContext } from 'react-hook-form';
import * as z from 'zod';
import { useAnomalyPriorities } from '../hooks/useAnomalyPriorities';
import { useAnomalyStatus } from '../hooks/useAnomalyStatus';
import { useAnomalyTypes } from '../hooks/useAnomalyTypes';

export const anomalySchema = z.object({
  title: z.string().trim().min(1).max(100),
  stateByGuid: z.record(z.string(), z.nativeEnum(ObjectAnomalyInformation)),
  description: z.string().trim().min(1),
  type: z.nativeEnum(AnomalyType),
  priority: z.nativeEnum(AnomalyPriority),
  status: z.nativeEnum(AnomalyStatus),
  labels: z.array(z.string().trim().min(1)).optional(),
  viewpoint: z.string().optional()
});

export const anomalyFormSchema = z.object({
  anomalySchema
});

export type AnomalyFormSchema = z.infer<typeof anomalyFormSchema>;

interface TeiaAnomalyFormProps {
  buttonLabel: string;
  snapshot?: string;
}

export const TeiaAnomalyForm = (props: TeiaAnomalyFormProps) => {
  const { buttonLabel, snapshot } = props;
  const { t } = usePluginTranslation();
  const { control } = useFormContext<AnomalyFormSchema>();
  const anomalyPriorities = useAnomalyPriorities();
  const anomalyStatus = useAnomalyStatus();
  const anomalyTypes = useAnomalyTypes();

  return (
    <Stack direction="column" spacing={4}>
      <Stack direction="row" spacing={6}>
        <Box sx={{ maxWidth: '320px', minHeight: '100%' }}>
          <img
            className="st-block st-h-auto st-min-w-20 st-max-w-44 st-min-h-full st-object-cover"
            alt=""
            src={snapshot ? `data:image/png;base64,${snapshot}` : PlaceholderImage}
          />
        </Box>
        <Stack direction="column" spacing={4} sx={{ width: '100%' }}>
          <Controller
            rules={{ required: true, min: 1 }}
            control={control}
            name="anomalySchema.title"
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={field.name}
                label={t('anomaly_plugin.widget.form.title.label')}
                placeholder={t('anomaly_plugin.widget.form.title.placeholder')}
                error={error !== undefined}
                helperText={error?.message}
                required
                onChange={field.onChange}
                name={field.name}
                value={field.value}
              />
            )}
          />
          <Controller
            control={control}
            name="anomalySchema.description"
            render={({ field, fieldState: { error } }) => (
              <Textarea
                sx={{ minWidth: 200 }}
                id={field.name}
                placeholder={t('anomaly_plugin.widget.form.description.placeholder')}
                label={t('anomaly_plugin.widget.form.description.label')}
                error={error !== undefined}
                helperText={error?.message}
                required
                onChange={field.onChange}
                name={field.name}
                value={field.value}
              />
            )}
          />

          <Stack direction="row" spacing={4}>
            <Box sx={{ width: 'calc(100% / 3)' }}>
              <Controller
                control={control}
                name="anomalySchema.status"
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete<AnomalyStatus, false, true>
                    value={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => anomalyStatus[option]}
                    disableClearable
                    disablePortal
                    id={field.name}
                    options={Object.keys(anomalyStatus) as Array<AnomalyStatus>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={t('anomaly_plugin.widget.form.status.label')}
                      />
                    )}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: 'calc(100% / 3)' }}>
              <Controller
                control={control}
                name="anomalySchema.priority"
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete<AnomalyPriority, false, true>
                    value={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => anomalyPriorities[option]}
                    disableClearable
                    disablePortal
                    id={field.name}
                    options={Object.keys(anomalyPriorities) as Array<AnomalyPriority>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={t('anomaly_plugin.widget.form.priority.label')}
                      />
                    )}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: 'calc(100% / 3)' }}>
              <Controller
                control={control}
                name="anomalySchema.type"
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete<AnomalyType, false, true>
                    value={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => anomalyTypes[option]}
                    disablePortal
                    id={field.name}
                    options={Object.keys(anomalyTypes) as Array<AnomalyType>}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={t('anomaly_plugin.widget.form.type.label')}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Button type="submit" sx={{ ml: 'auto' }}>
        {buttonLabel}
      </Button>
    </Stack>
  );
};
