import { TrashIcon } from '@heroicons/react/24/outline';
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  Text,
  TextField
} from '@stereograph/teia-system-design';
import { OpenDataAction } from '@stereograph/teia-system-design/twin-api';
import { usePluginTranslation } from '@translation';
import { Controller, useFormContext } from 'react-hook-form';
import { OpenDataAutoCompleteOption } from '../utils';
import { OpenDataSchema } from '../widgets/TeiaOpenDataForm';

interface OpenDataActionFieldProps {
  index: number;
  removeField: (index: number) => void;
}

export const OpenDataActionField = (props: OpenDataActionFieldProps) => {
  const { index, removeField } = props;
  const { t } = usePluginTranslation();
  const { control } = useFormContext<OpenDataSchema>();

  const options: Array<OpenDataAutoCompleteOption> = [
    {
      value: OpenDataAction.Add,
      label: t('open_data_plugin.form.action.add.text')
    },
    {
      value: OpenDataAction.Update,
      label: t('open_data_plugin.form.action.update.text')
    },
    {
      value: OpenDataAction.Delete,
      label: t('open_data_plugin.form.action.delete.text')
    },
    {
      value: OpenDataAction.AddOrUpdate,
      label: t('open_data_plugin.form.action.add_or_update.text')
    }
  ];

  return (
    <Stack
      sx={{
        boxShadow: '0px 4px 4px 0px #00000040',
        borderRadius: '0.375rem',
        padding: 4
      }}
      spacing={4}
    >
      <Stack direction="row">
        <Text className="st-text-xl st-mb-4 st-font-bold">
          {t('open_data_plugin.form.data.title')}
        </Text>
        <Text className="st-text-red-500 st-ml-0.5">*</Text>
      </Stack>

      <Stack direction="row" spacing={4}>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Controller
            control={control}
            name={`propertySchema.${index}.key`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={field.name}
                label={t('open_data_plugin.form.property.key.placeholder')}
                placeholder={t('open_data_plugin.form.property.key.placeholder')}
                error={error !== undefined}
                helperText={error?.message}
                required
                onChange={field.onChange}
                name={field.name}
                value={field.value}
              />
            )}
          />

          <Controller
            control={control}
            name={`propertySchema.${index}.value`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={field.name}
                label={t('open_data_plugin.form.property.value.placeholder')}
                placeholder={t('open_data_plugin.form.property.value.placeholder')}
                error={error !== undefined}
                helperText={error?.message}
                required
                onChange={field.onChange}
                name={field.name}
                value={field.value}
              />
            )}
          />

          <Controller
            control={control}
            name={`propertySchema.${index}.action`}
            render={({ field }) => (
              <Autocomplete<OpenDataAutoCompleteOption, false, true>
                defaultValue={options[0]}
                onChange={(_, value) => {
                  if (typeof value === 'object') {
                    field.onChange(value?.value);
                  }
                }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disableClearable
                id={field.name}
                options={options}
                renderInput={(params) => (
                  <TextField {...params} required label={t('open_data_plugin.form.action.title')} />
                )}
              />
            )}
          />
        </Stack>

        <Box>
          <IconButton
            aria-label={t('patch_data_plugin.form.property.delete_property_label')}
            color="error"
            onClick={() => removeField(index)}
            sx={{
              width: '36px',
              height: '36px'
            }}
          >
            <TrashIcon />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};
