import { TwinApi } from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';

export const useTwinTotalObjects = () => {
  const twin = useTwinSource();
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const { data } = useQuery({
    queryKey: ['Twin', 'info'],
    queryFn: async () => {
      return TwinApi(client).getTwinInfo(twin);
    }
  });

  return data?.twinObjectsCount ?? 0;
};
