import { setupViewerTranslation } from '@stereograph/teiaviewer';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next);
i18next.use(LanguageDetector);
i18next.init({
  fallbackLng: 'en',
  debug: true,
  resources: {}
});

setupViewerTranslation();
