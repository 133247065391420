import { TeiaSearchCondition } from '@stereograph/teia-system-design/twin-api';

const COLUMN_SEPARATOR = '//';

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * This function receives a column name and returns a pretty version of it.
 * The column name is split by the COLUMN_SEPARATOR and each part is capitalized.
 */
export const prettyColumnName = (columnName: string): string => {
  return columnName
    .replace('twinBimDataProperties//', '')
    .split(COLUMN_SEPARATOR)
    .map(capitalize)
    .join(' ');
};

export const removeUnfilterableColumns = <TColumn extends { id: string }>(
  columns: Array<TColumn>
): Array<TColumn> => {
  return columns.filter((column) => column.id !== 'parentId');
};

const getBubbleData = (bubbleData: string): 'Property' | 'UserData' => {
  switch (bubbleData) {
    case 'twinBimData':
      return 'Property';
    case 'twinUserData':
      return 'UserData';
    default:
      return 'Property';
  }
};

/**
 * This function checks if the column is from bubble data.
 * The columns send by the API and the table are in the format: bubbleData//property//name like twinBimData//Element Specific//Type
 * So, if the column has 3 parts, it is from bubble data.
 */
const isColumnFromBubbleData = (explodedColumn: Array<string>): boolean =>
  explodedColumn.length === 3;

export const extractSearchRuleFromTableFilter = (tableFilter: {
  id: string;
  value: unknown;
}): TeiaSearchCondition => {
  const explodedColumn = tableFilter.id.split(COLUMN_SEPARATOR);
  if (isColumnFromBubbleData(explodedColumn)) {
    return {
      name: explodedColumn[2],
      operator: 'Contains',
      type: getBubbleData(explodedColumn[0]),
      property: explodedColumn[1],
      value: tableFilter.value as string
    };
  }
  if (tableFilter.id === 'guid') {
    return {
      operator: 'Contains',
      type: 'Guid',
      value: tableFilter.value as string
    };
  }
  const capitalizedColumn = capitalize(tableFilter.id);

  return {
    name: capitalizedColumn,
    operator: 'Contains',
    type: 'Attribute',
    value: tableFilter.value as string
  };
};
