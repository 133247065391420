import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Icon, Stack, Text } from '@stereograph/teia-system-design';
import { AnomalyHistoryProperty } from '@stereograph/teia-system-design/twin-api';
import { usePluginTranslation } from '@translation';
import { TeiaAnomalyHistoryChip } from './TeiaAnomalyHistoryChip';

interface TeiaAnomalyHistoryItemUpdatedProps {
  property: AnomalyHistoryProperty,
}

export const TeiaAnomalyHistoryItemUpdated = (props: TeiaAnomalyHistoryItemUpdatedProps) => {
  const { property } = props;
  const { t } = usePluginTranslation();

  return (
    <Stack direction="row" spacing={2} alignItems="center" key={property.propertyName}>
      <TeiaAnomalyHistoryChip
        color="warning"
        label={t('anomaly_plugin.widget.history.updated.label')}
      />
      <Text bold>{property.propertyName}</Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text color="grey">{property.oldValue}</Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text>{property.newValue}</Text>
    </Stack>
  );
};
