import { Icon, IconButton, Tooltip } from '@stereograph/teia-system-design';
import { Anomaly } from '@stereograph/teia-system-design/twin-api';
import { useMemo } from 'react';
import { usePluginTranslation } from '@translation';
import { createAnomalyScene } from '../../widgets/utils';
import { EyeIcon } from '@heroicons/react/24/outline';
import { SetAnomalySceneCommand } from '../../command/SetAnomalySceneCommand';
import {
  CenterCameraOnSceneCommand,
  SetCameraPositionCommand,
  useTeiaViewerContext
} from '@stereograph/teiaviewer';
import { transformVectorOnUpVector } from '../../utils/transformVectorOnUpVector';
import { Vector3 } from 'three';

interface VisualizeSelectionButtonProps {
  anomaly: Anomaly;
}

export const VisualizeSelectionButton = (props: VisualizeSelectionButtonProps) => {
  const { anomaly } = props;
  const { t } = usePluginTranslation();
  const { viewer } = useTeiaViewerContext();

  const anomalyScene = useMemo(
    () => (anomaly.stateByGuid ? createAnomalyScene(anomaly.stateByGuid) : null),
    [anomaly]
  );
  const onClick = () => {
    if (anomalyScene) {
      const anomalySceneCommand = new SetAnomalySceneCommand(viewer, anomalyScene);
      anomalySceneCommand.execute();
    }
    const upVector = new Vector3(
      anomaly.anomalyViewPointDto.cameraUpVector.x,
      anomaly.anomalyViewPointDto.cameraUpVector.y,
      anomaly.anomalyViewPointDto.cameraUpVector.z
    );
    const position = transformVectorOnUpVector(
      new Vector3(
        anomaly.anomalyViewPointDto.cameraViewPoint.x,
        anomaly.anomalyViewPointDto.cameraViewPoint.y,
        anomaly.anomalyViewPointDto.cameraViewPoint.z
      ),
      upVector
    );
    const target = transformVectorOnUpVector(
      new Vector3(
        anomaly.anomalyViewPointDto.cameraDirection.x,
        anomaly.anomalyViewPointDto.cameraDirection.y,
        anomaly.anomalyViewPointDto.cameraDirection.z
      ),
      upVector
    );
    // If the position is empty and target are empty, center the camera on the scene
    if (position.length() === 0 && target.length() === 0) {
      const centerCamera = new CenterCameraOnSceneCommand(viewer);
      centerCamera.execute();
      return;
    }
    const cameraCommand = new SetCameraPositionCommand(viewer, position, target);
    cameraCommand.execute();
  };
  return (
    <Tooltip
      title={t('anomaly_plugin.resume_widget.table.columns.actions.visualize_selection_tooltip')}
      placement={'top'}
    >
      <IconButton
        aria-label={t(
          'anomaly_plugin.resume_widget.table.columns.actions.visualize_selection_tooltip'
        )}
        onClick={onClick}
      >
        <Icon component={EyeIcon} />
      </IconButton>
    </Tooltip>
  );
};
