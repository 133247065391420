import { TeiaViewer, useTweakPaneContext, useViewerContext } from '@stereograph/teiaviewer';
import { BindingApi, ButtonApi } from '@tweakpane/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Environment, useTeiaAuth } from '../utils/TeiaAuthentication';

const getTeiaTwinUrl = (env: Environment, port: number) => {
  let envName = '';
  switch (env) {
    case Environment.Develop:
      envName = '-develop';
      break;
    case Environment.Staging:
      envName = '-staging';
      break;
    case Environment.Preprod:
      envName = '-preprod';
      break;
    case Environment.Localhost:
      return `https://localhost:${port}/`;
  }

  return `https://teiatwin${envName}.teia-solution.com/`;
};

/**
 * @category tools
 */
export const TeiaLoginDevToolsPane = () => {
  const { pane } = useTweakPaneContext();
  const { i18n } = useTranslation();
  const { viewer } = useViewerContext<TeiaViewer>();
  const { isAuthenticated, user, signinRedirect, signoutRedirect, setTargetEnv, targetEnv } =
    useTeiaAuth();

  const { current: params } = React.useRef({
    user: user?.profile.name ?? '',
    environment: targetEnv,
    port: 44331,
    language: i18n.languages[0] ?? 'en'
  });

  const loginButtonRef = React.useRef<ButtonApi | null>(null);
  const logoutButtonRef = React.useRef<ButtonApi | null>(null);
  const userLabelRef = React.useRef<BindingApi | null>(null);
  const portInputRef = React.useRef<BindingApi | null>(null);

  React.useEffect(() => {
    const inputs = drawControls();
    return () => {
      inputs.forEach((input) => input.dispose());
    };
  }, []);

  React.useEffect(() => {
    const signIn = () => {
      signinRedirect();
    };
    loginButtonRef.current?.on('click', signIn);
  }, [signinRedirect, loginButtonRef.current]);

  React.useEffect(() => {
    const signOut = () => {
      signoutRedirect({ post_logout_redirect_uri: window.location.href });
    };
    logoutButtonRef.current?.on('click', signOut);
  }, [signoutRedirect, logoutButtonRef.current]);

  React.useEffect(() => {
    if (userLabelRef.current && logoutButtonRef.current && loginButtonRef.current) {
      userLabelRef.current.hidden = !isAuthenticated;
      logoutButtonRef.current.hidden = !isAuthenticated;
      loginButtonRef.current.hidden = isAuthenticated;
      pane.refresh();
    }
  }, [isAuthenticated, pane]);

  React.useEffect(() => {
    if (user) {
      const instance = user.profile.instance_name ?? '<Instance>';
      const name = user.profile.name ?? '<User>';
      params.user = `${name} - ${instance}`;
      params.environment = getEnvFromLocalStorage();
      pane.refresh();
      viewer.settings.twinApi.token.value = user.access_token;
      viewer.settings.twinApi.baseUrl.value = getTeiaTwinUrl(params.environment, params.port);
    }
  }, [params, user, pane, viewer]);

  const updateEnvironment = () => {
    setTargetEnv(params.environment);
    if (portInputRef.current) {
      portInputRef.current.hidden = params.environment !== Environment.Localhost;
    }
  };

  const updateLanguage = () => {
    i18n.changeLanguage(params.language);
  };

  const drawControls = () => {
    const langInput = pane
      .addBinding(params, 'language', {
        options: { en: 'en', fr: 'fr', es: 'es' }
      })
      .on('change', updateLanguage);
    const envInput = pane
      .addBinding(params, 'environment', {
        options: {
          Develop: Environment.Develop,
          Staging: Environment.Staging,
          Preprod: Environment.Preprod,
          Localhost: Environment.Localhost
        }
      })
      .on('change', updateEnvironment);
    const portInput = pane.addBinding(params, 'port', { step: 1 });
    const loginButton = pane.addButton({ title: 'Login' });
    const logoutButton = pane.addButton({ title: 'Logout' });
    const userInput = pane.addBinding(params, 'user', { readonly: true });
    userInput.hidden = !isAuthenticated;
    portInput.hidden = params.environment !== Environment.Localhost;

    // Maximize value input width
    const node = userInput.element.querySelector('.tp-lblv_v');
    if (node) {
      (node as HTMLElement).style.flexGrow = '2';
    }

    portInputRef.current = portInput;
    loginButtonRef.current = loginButton;
    logoutButtonRef.current = logoutButton;
    userLabelRef.current = userInput;
    return [loginButton, userInput, envInput, langInput, logoutButton, portInput];
  };

  return <></>;
};

function getEnvFromLocalStorage() {
  const env = localStorage.getItem('viewer-target-env') as Environment | null;
  if (env) {
    return env;
  }
  return Environment.Develop;
}
