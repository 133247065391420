import { TeiaQuantityTakeoffColumn } from '../types';
import { teiaQuantityTakeoffColumnDTO } from './teiaQuantityTakeoffColumnDTO';

export const teiaQuantityTakeoffColumnListDTO = (
  columns: Array<string>
): Array<TeiaQuantityTakeoffColumn> => {
  const columnsOptions: Array<TeiaQuantityTakeoffColumn> = [];
  columns.sort((a, b) => a.localeCompare(b));
  for (const column of columns) {
    const prettyColumn = teiaQuantityTakeoffColumnDTO(column);
    columnsOptions.push(prettyColumn);
  }
  return columnsOptions;
};
