import { Autocomplete, TextField } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { TeiaQuantityTakeoffColumn } from '../types';

interface TeiaQuantityTakeoffTableCellAggregateFieldProps {
  groupBy: TeiaQuantityTakeoffColumn | null;
  options: Array<TeiaQuantityTakeoffColumn>;
  onChange: (groupBy: TeiaQuantityTakeoffColumn | null) => void;
}

export const TeiaQuantityTakeoffTableCellAggregateField = (
  props: TeiaQuantityTakeoffTableCellAggregateFieldProps
) => {
  const { groupBy, options, onChange } = props;
  const { t } = usePluginTranslation();
  return (
    <Autocomplete<TeiaQuantityTakeoffColumn>
      size={'small'}
      id="qto-groupBy"
      getOptionLabel={(option) => {
        if (typeof option === 'object') {
          return option.title;
        }
        return '';
      }}
      getOptionKey={(option) => {
        if (typeof option === 'object') {
          return option.value;
        }
        return '';
      }}
      options={options}
      value={groupBy}
      renderInput={(params) => (
        <TextField {...params} label={t('quantity_takeoff_plugin.aggregate_input_placeholder')} />
      )}
      sx={{ width: 300 }}
      onChange={(_, value) => {
        if (typeof value === 'object') {
          onChange(value as TeiaQuantityTakeoffColumn);
        }
      }}
    />
  );
};
