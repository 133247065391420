import { OpenWidgetCommand, ViewerBehavior } from '@stereograph/teiaviewer';

export class ShowDevToolsWidgetBhavior extends ViewerBehavior {
  readonly name = 'ShowDevToolsWidgetBehavior';

  override setup(): void {
    super.setup();
    this.viewer.signals.onViewportReady.connect(this.onViewportReady);
  }
  override dispose(): void {
    super.dispose();
    this.viewer.signals.onViewportReady.disconnect(this.onViewportReady);
  }

  onViewportReady = () => {
    const command = new OpenWidgetCommand(this.viewer, 'DevTools');
    command.execute();
  };
}
