import { Button, Stack, TextField } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';

interface TeiaQuantityTakeoffExportFormProps {
  onCancel: () => void;
  onSubmit: (name: string) => void;
}

const isValidNameValue = (name: string | null) => {
  return name && name.trim().length > 0;
};

export const TeiaQuantityTakeoffExportForm = (props: TeiaQuantityTakeoffExportFormProps) => {
  const { onSubmit, onCancel } = props;
  const { t } = usePluginTranslation();
  const [nameInputError, setNameInputError] = useState<string | null>(null);
  const [nameExport, setNameExport] = useState<string>('');

  const handleNameExportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameExport(event.target.value.replace('.xlsx', ''));
  };

  const isValidName = (name: string | null) => {
    if (!isValidNameValue(name)) {
      setNameInputError(t('quantity_takeoff_plugin.export_modal.form.name.error_label'));
      return false;
    }
    setNameInputError(null);
    return true;
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidName(nameExport)) {
      onSubmit(nameExport);
    }
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Stack spacing={4}>
        <TextField
          id={'teia-qto-export-form-name'}
          label={t('quantity_takeoff_plugin.export_modal.form.name.label')}
          required
          error={nameInputError !== null}
          value={nameExport}
          onChange={handleNameExportChange}
          helperText={nameInputError}
          autoFocus={true}
        />
        <Stack spacing={2} direction={'row'} justifyContent={'center'}>
          <Button color={'info'} type={'button'} onClick={onCancel}>
            {t('quantity_takeoff_plugin.export_modal.form.cancel_button.label')}
          </Button>
          <Button type={'submit'}>
            {t('quantity_takeoff_plugin.export_modal.form.submit_button.label')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
