import { Chip } from '@stereograph/teia-system-design';

interface TeiaAnomalyHistoryChipProps {
  color: 'primary' | 'warning' | 'error';
  label: string;
}

export const TeiaAnomalyHistoryChip = (props: TeiaAnomalyHistoryChipProps) => {
  const { color, label } = props;

  return (
    <Chip
      color={color}
      size="small"
      sx={{ width: '7rem' }}
      label={label}
    />
  );
};
