import { PatchDataApi, PatchDataPostRequest, Twin } from '@stereograph/teia-system-design/twin-api';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const usePatchData = (twin: Twin) => {
  const queryKeys = ['PatchData'];
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();

  const { mutateAsync: submitPatchData } = useMutation({
    mutationFn: async (t: { patchDataRequest: PatchDataPostRequest }) => {
      await PatchDataApi(client).postPatchData(twin.projectId, twin.type, t.patchDataRequest);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: [...queryKeys, twin.projectId] });
    }
  });

  return {
    submitPatchData
  };
};
